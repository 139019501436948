﻿.user-badge,
.arsv-user-badge {
    font-size: 1em;
    white-space: nowrap;
    text-transform: none;
    color: getColor(base, primary);
    user-select: none;
    outline: none;

    > .picture {
        position: relative;
        display: inline-block;
        width: 2.5em;
        height: 2.5em;
        margin: 0 4px;
        line-height: 2.5em;
        text-align: center;
        text-transform: uppercase;
        font-weight: 300;
        border-radius: 50%;
        font-style: normal;
        color: getColor(base, standout);
        background-color: getColor(base, primary);

        &.-notifications::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -.25em;
            z-index: 0;
            width: .833em;
            height: .833em;
            border-radius: 50%;
            background-color: getColor(state, error);
        }
    }

    &.-inverted {
        color: getColor(base, standout);

        > .picture {
            color: getColor(base, primary);
            background-color: getColor(base, standout);
        }
    }

    &.-responsive {

        > .firstname,
        > .lastname {
            display: none;
        }

        @media(--large) {

            > .firstname {
                display: inline;
            }
        }

        @media(--extra-large) {

            > .lastname {
                display: inline;
            }
        }
    }
}
