﻿.avcb-lot-detail {

    > .auctionhouse {
        max-width: 25em;
        height: 1.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 700;
    }
}