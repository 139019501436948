﻿.avcb-modal-window.-auction-detail-modal {

    > .modalcontent {
        padding: 0;

        > .modalclose {
            z-index: 10;
        }

        > .details {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            padding: $gutter*1.5;

            > .images {
                width: 100%;


                > div > .imagelarge,
                > div > .imagesmall {
                    box-shadow: 0 0 1px rgba(0,0,0,.25);
                }
            }

            > h3 {
                text-transform: uppercase;
                font-size: typeScale(3);
            }

            > h4 {
                margin: 0;
                padding: 1rem 0 0 0;
            }

            > p {
                margin: 0;
                padding: 0 0 .5em 0;
            }

            > ul {
                list-style: none;
                margin: 0;
                padding: 0 0 .5em 0;
            }
        }
    }

    @media(--medium) {

        > .modalcontent {

            > .details {

                > .images {
                    width: 50%;
                    max-width: 480px;
                    float: left;
                    margin: 0 $gutter $gutter 0;
                }

                &::after {
                    content: '';
                    display: table;
                    width: 100%;
                    height: 0;
                    clear: both;
                }
            }
        }
    }
}
