$component-prefix: 'avcb';

// ../ArsValue.Website/commons/src/scss/components/alert-message
$alert-message-export-class: true;
$alert-message-prefix: $component-prefix;
$alert-message-default-color: darken(getColor(background, light), 30%);
$alert-message-success-color: getColor(state, success);
$alert-message-error-color: getColor(state, error);
$alert-message-warning-color: getColor(state, warning);
$alert-message-transition: $transition-duration;

// ../ArsValue.Website/commons/src/scss/components/button-link
$button-link-export-class: true;
$button-link-prefix: $component-prefix;
$button-link-font: $font-primary;
$button-link-color: getColor(text, inverted);
$button-link-background: getColor(base, primary);
$button-link-border: false;
$button-link-transition: $transition-duration;

// ../ArsValue.Website/commons/src/scss/components/document-detail
$document-detail-export-class: true;
$document-detail-prefix: $component-prefix;
$document-detail-background: getColor(background, body);
$document-detail-approved: getColor(state, success);
$document-detail-rejected: getColor(state, error);
$document-detail-pending: getColor(state, warning);
$document-detail-border: getColor(base, lines);

// ../ArsValue.Website/commons/src/scss/components/modal-window
$modal-window-export-class: true;
$modal-window-prefix: $component-prefix;
$modal-window-background-color: rgba(0,0,0,.75);
$modal-window-content-background: getColor(background, body);
$modal-window-transition-duration: $transition-duration;

// ../ArsValue.Website/commons/src/scss/components/page-jumper
$page-jumper-export-class: true;
$page-jumper-prefix: $component-prefix;
$page-jumper-color: getColor(text, primary);

// ../ArsValue.Website/commons/src/scss/components/simple-carousel
$simple-carousel-export-class: true;
$simple-carousel-prefix: $component-prefix;
$simple-carousel-background: #000;
$simple-carousel-slide-background: #000;
$simple-carousel-ticker-time: 5000ms;
$simple-carousel-transition-time: 750ms;

// ../ArsValue.Website/commons/src/scss/components/state-label
$state-label-export-class: true;
$state-label-prefix: 'arsv';
$state-label-font-size: 11px;
$state-label-background: getColor(base, primary);
$state-label-color: getColor(text, inverted);
$state-label-states: map-get($colors, state);

// ../ArsValue.Website/commons/src/scss/components/menu/horizontal-menu
$horizontal-menu-export-class: true ;
$horizontal-menu-prefix: $component-prefix;
$horizontal-menu-color: getColor(text, inverted);
$horizontal-menu-standout: getColor(text, inverted);
$horizontal-menu-transition: 450ms;
$horizontal-menu-border: getColor(base, lines);

// ../ArsValue.Website/commons/src/scss/components/menu/vertical-menu
$vertical-menu-export-class: true;
$vertical-menu-prefix: $component-prefix;
$vertical-menu-font-size: 1rem;
$vertical-menu-color: getColor(base, primary);
$vertical-menu-standout: getColor(base, standout);
$vertical-menu-border: getColor(base, lines);
$vertical-menu-max-width: 400px;

// ../ArsValue.Website/commons/src/scss/components/menu/sidebar-menu
$sidebar-menu-export-class: true;
$sidebar-menu-prefix: $component-prefix;
$sidebar-menu-breakpoint: 960px;
$sidebar-menu-font-size: $vertical-menu-font-size;
$sidebar-menu-color: $vertical-menu-color;
$sidebar-menu-standout: $vertical-menu-standout;
$sidebar-menu-border: $vertical-menu-border;
$sidebar-menu-max-width: $vertical-menu-max-width;

// ../ArsValue.Website/commons/src/scss/components/forms/checkbox-field
$checkbox-field-export-class: true;
$checkbox-field-prefix: $component-prefix;
$checkbox-field-default-color: getColor(base, primary);
$checkbox-field-outline-color: getColor(base, standout);
$checkbox-field-error-color: getColor(state, error);
$checkbox-field-disabled-color: getColor(state, muted);

// ../ArsValue.Website/commons/src/scss/components/forms/file-upload
$file-upload-export-class: true;
$file-upload-prefix: $component-prefix;
$file-upload-default-color: getColor(base, lines);
$file-upload-outline-color: getColor(base, standout);

// ../ArsValue.Website/commons/src/scss/components/forms/input-field
$input-field-export-class: true;
$input-field-prefix: $component-prefix;
$input-field-border-color: getColor(base, lines);
$input-field-error-color: getColor(state, error);
$input-field-disabled-color: getColor(state, muted);

// ../ArsValue.Website/commons/src/scss/components/forms/radio-field
$radio-field-export-class: true;
$radio-field-prefix: $component-prefix;
$radio-field-default-color: getColor(base, primary);
$radio-field-outline-color: getColor(base, standout);
$radio-field-error-color: getColor(state, error);
$radio-field-disabled-color: getColor(state, muted);

// ../ArsValue.Website/commons/src/scss/components/forms/select-field
$select-field-export-class: true;
$select-field-prefix: $component-prefix;
$select-field-max-width: 60em;
$select-field-color: getColor(base, primary);
$select-field-outline-color: getColor(base, standout);
$select-field-error-color: getColor(state, error);
$select-field-disabled-color: getColor(state, muted);

// ../ArsValue.Website/commons/src/scss/components/forms/textarea-field
$textarea-field-export-class: true;
$textarea-field-prefix: $component-prefix;
$textarea-field-font: $font-primary;
$textarea-field-default-color: getColor(base, lines);
$textarea-field-outline-color: getColor(base, standout);
$textarea-field-error-color: getColor(state, error);
$textarea-field-disabled-color: getColor(state, muted);

// ../ArsValue.Website/commons/src/scss/components/auction/auction-description
$auction-description-export-class: true;
$auction-description-prefix: $component-prefix;
$auction-description-font-size-large: typeScale(4);
$auction-description-font-size-medium: typeScale(3);
$auction-description-font-size-small: typeScale(2);
$auction-description-max-width: 768px;
$auction-description-title-color: getColor(base, primary);
$auction-description-text-color: getColor(text, primary);


// ../ArsValue.Website/commons/src/scss/components/auction/auction-detail
$auction-detail-export-class: true;
$auction-detail-prefix: $component-prefix;
$auction-detail-max-width: $container-width;

// ../ArsValue.Website/commons/src/scss/components/auction/auction-images
$auction-images-export-class: true;
$auction-images-prefix: $component-prefix;

// ../ArsValue.Website/commons/src/scss/components/auction/auction-item
$auction-item-export-class: true;
$auction-item-prefix: $component-prefix;
$auction-item-breakpoint: 960px;
$auction-item-cover-max-size: 200px;

// ../ArsValue.Website/commons/src/scss/components/auction/auction-list
$auction-list-export-class: true;
$auction-list-prefix: $component-prefix;
$auction-list-box-item-size: 320px;
$auction-list-settings-bgcolor: getColor(background, light);
$auction-list-message-color: getColor(base, lines);

// ../ArsValue.Website/commons/src/scss/components/lot/lot-detail
$lot-detail-export-class: true;
$lot-detail-prefix: $component-prefix;

// ../ArsValue.Website/commons/src/scss/components/lot/lot-item
$lot-item-export-class: true;
$lot-item-prefix: $component-prefix;
$lot-item-max-height: 240px;
$lot-item-image-ratio: 3/4;

// ../ArsValue.Website/commons/src/scss/components/lot/lot-price
$lot-price-export-class: true;
$lot-price-prefix: 'arsv';
$lot-price-color: lighten(getColor(text, secondary), 20%);

// ../ArsValue.Website/commons/src/scss/components/lot/lot-status
$lot-status-export-class: true;
$lot-status-prefix: 'arsv';
$lot-status-background: getColor(background, light);
$lot-status-border: getColor(base, lines);
$lot-status-color: getColor(base, primary);
$lot-status-inverted-color: getColor(text, inverted);

// ../ArsValue.Website/commons/src/scss/components/lot/lot-zoom
$lot-zoom-export-class: true;
$lot-zoom-prefix: $component-prefix;
$lot-zoom-width: 420px;
$lot-zoom-background: getColor(base, lines);
$lot-zoom-standout: getColor(base, primary);

// ../ArsValue.Website/commons/src/scss/components/menu/accordion-menu
$accordion-menu-export-class: true;
$accordion-menu-prefix: $component-prefix;
$accordion-menu-max-width: 400px;
$accordion-menu-color: getColor(base, primary);
$accordion-menu-text-color: getColor(text, primary);
$accordion-menu-border-color: getColor(base, lines);
$accordion-menu-icon: '';

// ../ArsValue.Website/commons/src/scss/components/tables/data-table
$data-table-export-class: true;
$data-table-prefix: $component-prefix;
$data-table-border: getColor(base, lines);
$data-table-background: false;
$data-table-icons: getColor(base, primary);
