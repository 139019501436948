﻿$file-upload-export-class: true !default;
$file-upload-prefix: '' !default;
$file-upload-default-color: #ddd !default;
$file-upload-outline-color: #00f !default;

@mixin file-upload($default-color: #ddd, $outline-color: #00f) {
    position: relative;

    > .validationmessage {

        @if mixin-exists('validationmessage') {
            @include validationmessage();
            text-align: left;
        }
    }

    &.-no-js {

        > input[type="file"] {
            position: relative;
            display: inline-block;
            margin-left: .5em;
        }

        > .filename {
            display: none;
        }
    }

    &:not(.-no-js) {

        > input[type="file"] {
            position: absolute;
            left: -1000000px;

            &[disabled] + .uploadbutton {
                pointer-events: none;
                color: darken(#eee, 25%);
                border-color: #eee;
                background-color: #eee;
            }
        }

        > .uploadbutton {

            @if mixin-exists('button-link') {
                @include button-link( 
                    $button-link-font, 
                    $button-link-color, 
                    $button-link-background, 
                    $button-link-border, 
                    $button-link-transition 
                );

                > label {
                    position: relative;
                    z-index: 1;
                }

                &.-transparent {
                    color: $button-link-background;
                    background-color: transparent;

                    &::before {
                        background-color: $button-link-background;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $button-link-color;
                    }
                }

                &.-standout {
                    color: $button-link-background;
                    border-color: $outline-color;
                    background-color: $outline-color;

                    &::before {
                        background-color: $button-link-background;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $button-link-color;
                        border-color: $button-link-background;
                    }
                }
            }
        }

        > .filename {
            display: inline-block;

            > .reset {
                display: none;
            }

            &.-has-file {

                > .text {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 1em;
                    font-size: .833em;
                }

                > .reset {
                    display: inline-block;
                    margin-left: .5em;
                    vertical-align: middle;
                    font-weight: 700;
                    font-size: 125%;
                    color: getColor(base, lines);
                    cursor: pointer;
                }
            }
        }
    }

    &.-valid {

        > .uploadbutton::after {
            content: '';
            position: relative;
            @include labelbadge();
            @include labelbadge-valid();
            vertical-align: middle;
            margin: 0 0 .15em .5em;
        }
    }

    &.-error {

        > .uploadbutton::after {
            content: '';
            position: relative;
            @include labelbadge();
            @include labelbadge-error();
            vertical-align: middle;
            margin: 0 0 .15em .5em;
        }

        > .validationmessage {
            display: block;
        }
    }
}

%file-upload {
    @include file-upload($file-upload-default-color, $file-upload-outline-color);
}

@if $file-upload-export-class {

    @if str-length($file-upload-prefix) == 0 {

        .file-upload {
            @include file-upload($file-upload-default-color, $file-upload-outline-color);
        }
    }
    @else 
    {
        .#{$file-upload-prefix}-file-upload {
            @include file-upload($file-upload-default-color, $file-upload-outline-color);
        }
    }
}
