﻿@mixin validationmessage() {
    display: none;
    flex: 1 1 100%;
    font-size: typeScale(1);
    color: getColor(state, error);
    text-align: right;
    user-select: none;

    &.field-validation-error {
        display: block;
    }
}

@mixin field-label() {
    position: relative;
    width: 100%;
    padding-right: 40px;
    line-height: 1.2;
    white-space: nowrap;
}

@mixin labelbadge {
    display: inline-block;
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

@mixin labelbadge-error {
    background-color: getColor(state, error);
    @include svg-icon('warning-icon', getColor(text, inverted));
    background-size: 60%;
}

@mixin labelbadge-valid {
    background-color: getColor(state, success);
    @include svg-icon('check-icon', getColor(text, inverted));
    background-size: 60%;
}

@mixin labelbadge-warning {
    background-color: getColor(state, warning);
    @include svg-icon('warning-icon', getColor(text, inverted));
    background-size: 60%;
}