@import 'custom/settings';
@import '../../../node_modules/concise.css/concise';
@import 'custom/component-settings';
@import 'custom/icons';

@import 'layout/base';
@import 'layout/header';
@import 'layout/footer';

@import '../../../../ArsValue.Website/commons/src/scss/components/alert-message';
@import '../../../../ArsValue.Website/commons/src/scss/components/button-link';
@import '../../../../ArsValue.Website/commons/src/scss/components/document-detail';
@import '../../../../ArsValue.Website/commons/src/scss/components/modal-window';
@import '../../../../ArsValue.Website/commons/src/scss/components/page-jumper';
@import '../../../../ArsValue.Website/commons/src/scss/components/simple-carousel';
@import '../../../../ArsValue.Website/commons/src/scss/components/state-label';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-actions';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-badge';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-countdown';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-description';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-detail';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-images';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-item';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/auction-list';
@import '../../../../ArsValue.Website/commons/src/scss/components/auction/session-list-item';
@import '../../../../ArsValue.Website/commons/src/scss/components/house/house-badge';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/mixins';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/checkbox-field';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/file-upload';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/input-field';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/radio-field';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/select-field';
@import '../../../../ArsValue.Website/commons/src/scss/components/forms/textarea-field';
@import '../../../../ArsValue.Website/commons/src/scss/components/lot/lot-detail'; 
@import '../../../../ArsValue.Website/commons/src/scss/components/lot/lot-item';
@import '../../../../ArsValue.Website/commons/src/scss/components/lot/lot-price';
@import '../../../../ArsValue.Website/commons/src/scss/components/lot/lot-status'; 
@import '../../../../ArsValue.Website/commons/src/scss/components/lot/lot-zoom'; 
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/vertical-menu';
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/dropdown-menu';
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/horizontal-menu';
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/mobile-submenu';
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/sidebar-menu';
@import '../../../../ArsValue.Website/commons/src/scss/components/menu/accordion-menu';
@import '../../../../ArsValue.Website/commons/src/scss/components/tables/data-table';
@import '../../../../ArsValue.Website/commons/src/scss/components/user/user-badge';
@import 'components/header-wrapper';
@import 'components/footer-wrapper';
@import 'components/article-wrapper';
@import 'components/documents-wrapper';
@import 'components/form-wrapper';
@import 'components/auction-list';
@import 'components/lot-detail';
@import 'components/lot-zoom';
@import 'components/scrollable-text';

@import 'variants/auction-images.single-image';
@import 'variants/auction-list.lot-list';
@import 'variants/button-link.transparent';
@import 'variants/horizontal-menu.lot-navigation';
@import '../../../../ArsValue.Website/commons/src/scss/variants/input-field.address';
@import 'variants/modal-window.auction-detail-modal';


.avcb-input-field.-address {

    @extend .arsv-input-field.-address;
}
