﻿$auction-item-export-class: true !default;
$auction-item-prefix: '' !default;
$auction-item-breakpoint: 960px !default;
$auction-item-cover-max-size: 192px !default;

@mixin auction-item($breakpoint, $cover-max-size) {
    $mobile-width: 95vw;
    $cover-size: $cover-max-size/$breakpoint*100vw;
    $teaser-image-size: 75px;

    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;

    &:hover {
        z-index: 5;
    }

    > .cover {
        position: relative;
        user-select: none;

        > img {
            width: 100%;
            height: 100%;
            // https://github.com/fregante/object-fit-images
            @if mixin-exists('object-fit') {
                @include object-fit('contain');
                object-fit: contain;
            }
            @else {
                object-fit: contain;
            }
        }
    }

    > .description {
        position: relative;
        padding: 0 .5rem;
    }

    > .actions {
        position: relative;
    }


    @media (max-width: $breakpoint - 1) {
        width: $mobile-width;
        margin: 0 auto;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;



        > .cover {
            flex: 0 1 $cover-size;
            width: $cover-size;
            height: $cover-size;
            min-height: 100px;
        }

        > .description {
            flex: 1 1 $mobile-width - $cover-size;
            /*width: $mobile-width - $cover-size;
            max-width: $mobile-width - $cover-size;*/
            > .teaser {
                display: none;
            }
        }

        > .actions {
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
        }
    }

    @media(min-width: $breakpoint) {
        flex-direction: row;
        height: $cover-max-size;

        > .cover {
            flex: 1 1 $cover-max-size;
            width: $cover-max-size;
            min-width: $cover-max-size;
        }

        > .description {
            flex: 0 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;

            > .heading {
                flex: 0 1 1rem;
                margin-bottom: 0;
            }

            > .title {
                flex: 0 1 auto;
                max-height: 87px;
                overflow: hidden;
                line-height: 1;
            }

            > .content {
                flex: 1 1 auto;
            }

            > .teaser {
                flex: 0 1 $teaser-image-size;
                list-style: none;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
                max-height: $teaser-image-size;
                padding: 0;
                margin: 0 -.375rem;
                user-select: none;

                > .lot {
                    flex: 0 1 auto;
                    display: flex;
                    align-items: flex-end;
                    padding: 0 0.375rem;
                    height: 100%;
                    max-height: $teaser-image-size;
                    width: auto;

                    > img {
                        max-width: 100%;
                        height: $teaser-image-size;
                    }
                }
            }
        }

        > .actions {
            flex: 1 1 200px;
            min-width: 200px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: flex-end;

            > .badges,
            > .buttons {
                text-align: right;
            }

            > .buttons > a + a {
                margin-top: 6px;
            }
        }
    }
}

%auction-item {
    @include auction-item($auction-item-breakpoint, $auction-item-cover-max-size);
}

@if $auction-item-export-class {

    @if str-length($auction-item-prefix) == 0 {

        .auction-item {
            @include auction-item($auction-item-breakpoint, $auction-item-cover-max-size);
        }
    }
    @else {

        .#{$auction-item-prefix}-auction-item {
            @include auction-item($auction-item-breakpoint, $auction-item-cover-max-size);
        }
    }
}
