﻿$auction-images-export-class: true !default;
$auction-images-prefix: '' !default;

@mixin auction-images() {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 62%;

    > .imagelarge,
    > .imagesmall {
        margin: 0;
        background-position: center;
        background-size: contain;
        background-color: getColor(background, body);
        /*background-color: lighten(getColor(base, lines), 10%);*/
        > img {
            position: absolute;
            width: 100%;
            height: 100%;
            // https://github.com/fregante/object-fit-images
            @if mixin-exists('object-fit') {
                @include object-fit('contain');
                object-fit: contain;
            }
            @else {
                object-fit: contain;
            }
        }
    }

    > .imagelarge {
        position: absolute;
        top: 0;
        left: 0;
        width: 64%;
        height: 0;
        padding-bottom: 62%;
    }

    > .imagesmall {
        position: absolute;
        top: 0;
        right: 0;
        width: 35%;
        height: 49%;

        &:nth-of-type(3) {
            top: auto;
            bottom: 0;
        }
    }

    &:not(.-images-4) {

        > .imagesmall:nth-of-type(4) {
            display: none;
        }
    }

    > .imagesmall:last-of-type {
        bottom: 0;
        top: auto;
    }
    /** @variant */
    &.-images-3 {
        padding-bottom: 70%;

        > .imagelarge {
            width: 74%;
            padding-bottom: 70%;
        }

        > .imagesmall {
            width: 25%;
            height: 32.5%;

            &:nth-of-type(1) {
                top: 0;
            }

            &:nth-of-type(2) {
                top: 33.5%;
            }

            &:nth-of-type(3) {
                top: 67%;
            }
        }
    }

    &.-images-4 {
        padding-bottom: 70%;

        > .imagelarge {
            width: 74%;
            padding-bottom: 70%;
        }

        > .imagesmall {
            width: 25%;
            height: 32.5%;

            &:nth-of-type(2) {
                top: 0;
            }

            &:nth-of-type(3) {
                top: 33.5%;
            }

            &:nth-of-type(4) {
                top: 67%;
            }
        }
    }
}
%auction-images {
    @include auction-images();
}

@if $auction-images-export-class {

    @if str-length($auction-images-prefix) == 0 {
        .auction-images {
            @include auction-images();
        }
    }
    @else {
        .#{$auction-images-prefix}-auction-images {
            @include auction-images();
        }
    }
}
