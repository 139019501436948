html,
body {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
}

.avcb-layout-base {
    background-color: #fff;

    > .wrapper {

        > .content {

            &.-default-header {
            }

            &.-custom-header {
            }
        }
    }

    @media(--medium) {

        > .wrapper > .content {
            width: 100%;
            max-width: $container-width;
            margin: 0 auto;
            min-height: 75vh;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: stretch;
        }

        > .wrapper > .content > main {
            flex: 1 1 300px;

            > .messages {

                > .avcb-alert-message {
                    margin: $gutter*.5;
                }
            }
        }

        > .wrapper > .content > aside {
            flex: 0 1 300px;

            &[data-ui-component="bottom-drawer"] {

                > .title {
                    display: none;
                }
            }
        }
    }

    @media(max-width: 768px) {
        $bottom-drawer-top: 3em;

        > .wrapper {

            &.-custom-html {
                padding-bottom: $bottom-drawer-top;
            }

            > .content {

                > aside[data-ui-component="bottom-drawer"] {
                    position: fixed;
                    top: 100%;
                    left: 0;
                    z-index: 99;
                    width: 100%;
                    max-width: none;
                    border-top: 1px solid getColor(base, lines);
                    background-color: getColor(background, body);
                    transform: translateY($bottom-drawer-top * -1);
                    transition: transform 400ms;
                    box-shadow: 0 0 3px rgba(0,0,0,.25);

                    > .title {
                        position: relative;
                        white-space: normal;
                        padding: 1em 1.5em;
                        height: 2em;
                        line-height: 1;
                        text-transform: uppercase;
                        user-select: none;
                        color: #666;

                        > h3 {
                            font-size: 1em;
                            line-height: 1em;
                            margin: 0;
                        }

                        &::after {
                            content: '';
                            display: block;
                            position: absolute; 
                            top: .75em;
                            right: 1.5em;
                            width: 1.5em;
                            height: 1.5em;
                            @include svg-icon('menu-icon', #666);
                        }
                    }

                    > .avcb-vertical-menu {
                        max-width: none;
                    }
                    /*&::before {
                        content: '';
                        position: relative;
                        top: 1em;
                        display: block;
                        margin: 0 auto;
                        width: 2em;
                        height: 2em;
                        border-left: 1px solid getColor(base, lines);
                        border-top: 1px solid getColor(base, lines);
                        transform: rotate(45deg);
                        transition: transform 400ms, top 400ms;
                        cursor: pointer;
                    }*/
                    &.-open {
                        transform: translateY(-100%);


                        &::before {
                            transform: rotate(225deg);
                            top: 0;
                        }
                    }
                }
            }
        }
    }
}

