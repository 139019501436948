﻿$data-table-export-class: true !default;
$data-table-prefix: '' !default;
$data-table-border: false !default;
$data-table-background: false !default;
$data-table-icons: false !default;

@mixin data-table($border: false, $background: false, $icons: false) {
    position: relative;
    width: 100%;
    margin-bottom: 3rem;

    > .listsettings {
        position: relative;
        margin: 0;

        + .listsettings {
            margin-top: 0;
        }
    }


    > .tablemessages {

        > *:last-child {
            margin-bottom: 1.5rem;
        }
    }

    > table {
        min-width: 100%;
        // Heading
        > thead {
            background-color: transparent;
            user-select: none;

            @if type-of($border) == color {
                border-top: 1px solid $border;
                border-bottom: 1px solid $border;
            }

            > tr > th {
                padding: .5em 1em;
                line-height: 1.2;
            }
        }

        > tbody {
            position: relative;

            @if type-of($border) == color {
                border-bottom: 1px solid $border;
            }

            > tr {
                font-size: typeScale(2);

                @if type-of($border) == color {
                    background-color: transparent;
                    transition: background-color 250ms;

                    &:nth-of-type(even) {
                        background-color: lighten($border, 12%);
                    }

                    &:hover {
                        background-color: lighten($border, 10%);
                    }
                }

                & > td {
                    line-height: 1.25;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                        text-align: right;
                    }

                    &.rowaction {
                        vertical-align: middle;

                        input[type="submit"] {
                            display: inline-block;
                            appearance: none;
                            padding: 0;
                            margin: 0;
                            background-color: transparent;
                            font-weight: 700;
                            text-transform: uppercase;
                            transition: color 250ms;

                            @if type-of($border) == color {
                                color: $border;
                            }
                            @else {
                                color: #999;
                            }

                            &:hover,
                            &:focus,
                            &:active {

                                @if type-of($icons) == color {
                                    color: $icons;
                                }
                            }
                        }

                        &.-delete {

                            input[type="submit"] {
                                font-size: 250%;
                                margin-right: .5em;
                            }
                        }
                    }
                }
            }
        }

        > tbody > tr:last-child > td {
            padding-bottom: 10px;
        }
        // Table Footer
        > tfoot {
            text-align: center;
            background-color: transparent;
            // Empty table message
            > .tableempty {

                > td {
                    padding: 1.5em;
                    text-align: center;
                    user-select: none;
                    text-transform: uppercase;

                    @if type-of($border) == color {
                        color: darken($border, 15%);
                    }
                    @else {
                        color: #999;
                    }
                }
            }
        }
    }
    // Ensure empty table message is not displayed when table is not empty
    &:not(.-empty) {

        > table {

            > tfoot {

                > .tableempty {
                    display: none;
                }
            }
        }
    }
    // Loading table
    &.-loading {

        > table {

            > .tablecontent {
                // Hide content
                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10;
                    width: 100%;
                    height: 100%;

                    @if type-of($background) == color {
                        background-color: $background;
                    }
                    @else {
                        background-color: #fff;
                    }
                }

                @if mixin-exists(load-spinner) and type-of($icons) == color {

                    &::after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        z-index: 11;
                        margin-left: -1em 0 0 -1em;
                        @include load-spinner(2em, $icons);
                    }
                }
            }
        }
    }
}

%data-table {
    @include data-table( $data-table-border, $data-table-background, $data-table-icons );
}

@if $data-table-export-class {

    @if str-length($data-table-prefix) == 0 {
        .data-table {
            @include data-table( $data-table-border, $data-table-background, $data-table-icons );
        }
    }
    @else {
        .#{$data-table-prefix}-data-table {
            @include data-table( $data-table-border, $data-table-background, $data-table-icons );
        }
    }
}

.arsv-data-table {

    & > table {

        & > .tablecontent {


            & > .tablerow {


                &:first-child {

                    & > td {
                        padding-top: .5lh;
                    }
                }

                &:last-child {

                    & > td {
                        padding-bottom: .5lh;
                    }
                }
            }
        }

        th {

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                text-align: right;
            }
        }

        & .-left {
            text-align: left;
        }

        & .-right {
            text-align: right;
        }

        .arsv-button-link {
            font-size: 65%;
        }

        form .arsv-button-link {
            font-size: .833rem;
        }
    }

    > .tableactionconfirm {
        text-align: center;

        > .modalcontent {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;

            > .message {
                flex: 0 0 100%;
                padding: .5lh;
                margin-bottom: 1lh;
            }

            > .actions {
                flex: 0 0 100%;
                padding: .5lh 0;

                button {
                    margin: 0 .5lh;
                    min-width: 6rem;
                }
            }
        }
    }
}
