﻿.avcb-auction-list.-lot-list {

    > ul > .item {
        padding: 16px 0;
        border-bottom: 1px solid getColor(base, lines);

        &:last-child {
            border-bottom: 0;
        }
    }

    > .listsettings {
        flex-wrap: wrap;

        > .lotsearch {
            flex: 0 auto;

            > a,
            > button,
            > input[type="text"],
            > label {
                display: inline-block;
            }

            > a,
            > button {
                font-size: 10px;
            }

            > input[type="text"] {
                max-width: 12em;
                height: 30px;
                line-height: 30px;
                min-height: 30px;
                background-color: getColor(background, body);
            }
        }
    }
}
