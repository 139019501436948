﻿.avcb-form-wrapper {
    max-width: 720px;
    padding: 0 .5lh 1lh .5lh;

    > form {

        > * {
            margin-bottom: .5lh;
        }

        > .validationsummary.validation-summary-valid {
            display: none;
        }

        > .address {
            // Indirizzo
            > .streetname {
                width: auto;
                flex: 1 1 auto;
            }
            // Numero civico
            > .streetnumber {
                max-width: 6em;
                flex: 0 1 6em;
            }
        }

        > .city {
            // CAP
            > .avcb-input-field:first-child {
            }

            > .avcb-input-field:last-child {
            }
        }

        > .newdocument {
            text-align: center;

            > .uploadbutton {
                font-size: 1rem;
            }

            > .filename {
                display: block;
                width: 100%;
                font-size: 1rem;
                line-height: 2;
            }

            > .validationmessage {
                text-align: center;
            }
        }

        > button,
        > input[type="submit"] {
            margin-right: 1rem;
        }
    }

    @media(max-width: 480px) {

        > form {

            > .avcb-input-field,
            > .avcb-radio-field,
            > .avcb-select-field {

                > label,
                > select,
                > input:not([type="submit"]) {
                    flex: 1 1 100%;
                }
            }
        }
    }
}
