﻿.arsv-auction-actions {
    user-select: none;

    > .badges {
        padding: .5lh 0;

        > .badge {
            vertical-align: middle;
        }    
    }

    > .buttons {

        > .delete {
            display: inline-block;
            margin-left: .25rem
        }
    }
}