﻿$sidebar-menu-export-class: true !default;
$sidebar-menu-prefix: '' !default;
$sidebar-menu-breakpoint: 640px !default;
$sidebar-menu-font-size: $vertical-menu-font-size !default;
$sidebar-menu-color: $vertical-menu-color !default;
$sidebar-menu-standout: $vertical-menu-standout !default;
$sidebar-menu-border: $vertical-menu-border !default;
$sidebar-menu-max-width: $vertical-menu-max-width !default;

@mixin sidebar-menu($breakpoint, $font-size, $color, $standout, $border, $max-width) {
    @if mixin-exists('vertical-menu') {
        @include vertical-menu( $font-size, $color, $standout, $border, $max-width );
        padding: 0;
    }

    > .title {
        margin: 0 0 1rem 0;
        font-size: typeScale(5);
        line-height: 1.1;
        text-transform: uppercase;
    }

    > .content > .item.-disabled {
        pointer-events: none;

        > a {
            color: darken(getColor(base, lines), 15%);
            cursor: not-allowed;
        }
    }

    @media (max-width: $breakpoint) {
        @if mixin-exists('mobile-submenu') {
            @include mobile-submenu( $mobile-submenu-icon-color );
        }

        margin-bottom: 0 !important;
        max-width: none;
    }
}

@if $sidebar-menu-export-class {

    @if str-length($sidebar-menu-prefix) == 0 {
        .sidebar-menu {
            @include sidebar-menu( 
                $sidebar-menu-breakpoint,
                $sidebar-menu-font-size,
                $sidebar-menu-color,
                $sidebar-menu-standout,
                $sidebar-menu-border,
                $sidebar-menu-max-width
            )
        }
    }
    @else {
        .#{$sidebar-menu-prefix}-sidebar-menu {
            @include sidebar-menu( 
                $sidebar-menu-breakpoint,
                $sidebar-menu-font-size,
                $sidebar-menu-color,
                $sidebar-menu-standout,
                $sidebar-menu-border,
                $sidebar-menu-max-width
            )
        }
    }
}
@else {
    %sidebar-menu {
        @include sidebar-menu( 
            $sidebar-menu-breakpoint,
            $sidebar-menu-font-size,
            $sidebar-menu-color,
            $sidebar-menu-standout,
            $sidebar-menu-border,
            $sidebar-menu-max-width
        )
    }
}
