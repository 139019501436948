﻿$select-field-export-class: true !default;
$select-field-prefix: '' !default;
$select-field-max-width: 36em !default;
$select-field-color: #666 !default;
$select-field-outline-color: #009 !default;
$select-field-error-color: #900 !default;
$select-field-disabled-color: #eee !default;

@mixin select-field($max-width: 36em, $color: #666, $outline-color: #009, $error-color: #900, $disabled-color: #eee) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: $max-width;
    font-size: 1rem;

    > label {

        @if mixin-exists('field-label') {
            @include field-label();
        }

        > .labelrequired {
            color: $error-color;
        }

        > .labelbadge {

            @if mixin-exists('labelbadge') {
                position: absolute;
                right: 1em;
                top: 50%;
                margin-top: -11px;
                @include labelbadge();
            }
        }
    }

    > select {
        flex: 1 auto;
        width: auto;
        min-width: 10em;
        height: 1.875em;
        padding-left: 0;
        padding-right: 2.5em;
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        background-color: transparent;
        appearance: none;
        cursor: pointer;

        @if mixin-exists('svg-icon') {
            @include svg-icon('chevron-down-icon', $color, 1em, right .75em center);
        }

        &::-ms-expand {
            display: none;
        }

        &::-ms-value {
            color: currentColor;
        }

        &:not([disabled]):not([readonly]):focus,
        &:not([disabled]):not([readonly]):active {
            padding-left: .5em;
            background-color: rgba($outline-color, .25);
            color: $color;
            border-color: $color;
        }

        &[disabled],
        &[readonly] {
            color: darken($disabled-color, 15%);
            background-color: lighten($disabled-color, 2%);

            @if mixin-exists('svg-icon') {
            }
        }

        > option,
        > optgroup > option {
            padding: .5em;
            background-color: #fff;
            color: $color;
        }
    }

    > .validationmessage {

        @if mixin-exists('validationmessage') {
            @include validationmessage();
        }

        &.field-validation-error {
            display: block;
        }
    }

    &.-warning > label > .labelbadge {

        @if mixin-exists('labelbadge-warning') {
            display: block;
            @include labelbadge-warning();
        }
    }

    &.-valid > label > .labelbadge {

        @if mixin-exists('labelbadge-valid') {
            display: block;
            @include labelbadge-valid();
        }
    }

    &.-error {

        > label > .labelbadge {

            @if mixin-exists('labelbadge-error') {
                display: block;
                @include labelbadge-error();
            }
        }

        > select {
            color: $error-color;
            border-color: $error-color;
            @include svg-icon('chevron-down-icon', $error-color, 1em, right .75em center);
        }

        > .validationmessage {
            display: block;
        }
    }
}

%select-field {
    @include select-field(
        $select-field-max-width, 
        $select-field-color, 
        $select-field-outline-color,
        $select-field-error-color, 
        $select-field-disabled-color
    );
}

@if $select-field-export-class {

    @if str-length($select-field-prefix) == 0 {
        .select-field {
            @include select-field(
                $select-field-max-width, 
                $select-field-color, 
                $select-field-outline-color,
                $select-field-error-color, 
                $select-field-disabled-color
            );
        }
    }
    @else {
        .#{$select-field-prefix}-select-field {
            @include select-field(
                $select-field-max-width, 
                $select-field-color, 
                $select-field-outline-color,
                $select-field-error-color, 
                $select-field-disabled-color
            );
        }
    }
}
