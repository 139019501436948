﻿$page-jumper-export-class: true !default;
$page-jumper-prefix: '' !default;
$page-jumper-color: #000 !default;

@mixin page-jumper($color: #000) {
    display: inline;
    width: auto;
    white-space: nowrap;
    font-size: 1em;
    user-select: none;

    > form {
        display: inline;
        width: auto;
        white-space: nowrap;

        > button {
            display: inline-block;
            min-width: unset;
            height: 1em;
            min-height: unset;
            padding: 0 0 0 1em;
            margin: 0;
            border: 0;
            line-height: 1;
            background-color: transparent;
            text-transform: uppercase;
            color: $color;
            appearance: none;

            &:hover {
                text-decoration: underline;
            }
        }

        > label {
            display: inline;
            height: 1em;
            line-height: 1;
        }

        > select {
            width: auto;
            max-width: 6em;
            min-width: unset;
            height: 1em;
            min-height: unset;
            padding: 0 1em 0 .25em;
            margin: 0;
            border: 0;
            line-height: 1;
            font-weight: 700;
            color: $color;
            outline: none;
            cursor: pointer;
            appearance: none;

            @include svg-icon('chevron-down-icon', $color, .5em, right .25em center);


            > option:not([selected]) {
                font-weight: 400;
            }

            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }

    &:not(.-no-js) {

        > form > button {
            display: none;
        }
    }

    &.-ie11 {
        display: inline-block;
        max-width: 90px;
    }
}


%page-jumper {
    @include page-jumper($page-jumper-color);
}

@if $page-jumper-export-class {

    @if str-length($page-jumper-prefix) == 0 {
        .page-jumper {
            @include page-jumper($page-jumper-color);
        }
    }
    @else {
        .#{$page-jumper-prefix}-page-jumper {
            @include page-jumper($page-jumper-color);
        }
    }
}
