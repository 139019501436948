﻿.avcb-lot-zoom {

    @media(min-width: $lot-zoom-width) {
        flex-direction: column-reverse;
        justify-content: flex-end;

        > .thumblist {
            padding-top: 16px;
            padding-left: 0;
            text-align: left;
        }

        > .preview {
            flex: 0 0 $lot-zoom-width;
            width: 100%;
            height: $lot-zoom-width;
        }
    }
}