﻿$checkbox-field-export-class: true !default;
$checkbox-field-prefix: '' !default;
$checkbox-field-default-color: #333 !default;
$checkbox-field-outline-color: #00f !default;
$checkbox-field-error-color: #900 !default;
$checkbox-field-disabled-color: #ccc !default;

@mixin checkbox-field($default-color: #333, $outline-color: #00f, $error-color: #900, $disabled-color: #ccc) {
    position: relative;
    display: inline-block;

    > input[type="checkbox"] {
        position: absolute;
        left: -1000000px;

        + label,
        + input[type="hidden"] + label {
            position: relative;
            display: inline-block;
            padding-right: 36px;

            &::before {
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                border: 2px solid $default-color;
                vertical-align: middle;
                margin-top: -3px;
                margin-right: .5em;
            }

            > .labelbadge {
                position: absolute;
                top: 50%;
                right: 5px;
                margin-top: -11px;

                @if mixin-exists('labelbadge') {
                    @include labelbadge();
                }
            }

            > .labelrequired {
                display: none;
                color: getColor(state, error);
            }
        }

        &:checked + label::before,
        &:checked + input[type="hidden"] + label::before {
            @include svg-icon('check-icon', $default-color);
            background-size: 75%;
        }

        &:not([disabled]):not([readonly]) {

            &:focus + label::before,
            &:active + label::before,
            &:focus + input[type="hidden"] + label::before,
            &:active + input[type="hidden"] + label::before {
                border-color: $outline-color;
            }
        }

        &[disabled],
        &[readonly] {

            + label,
            + input[type="hidden"] + label {
                color: darken($disabled-color, 15%);
                cursor: not-allowed;

                &::before {
                    border-color: darken($disabled-color, 15%);
                    background-color: darken($disabled-color, 15%);
                }
            }

            &:checked {

                + label::before,
                + input[type="hidden"] + label::before {
                    @include svg-icon('check-icon', lighten($disabled-color, 100%));
                    background-size: 75%;
                }
            }
        }
    }

    .validationmessage {

        @if mixin-exists('validationmessage') {
            @include validationmessage();
        }

        &.field-validation-error {
            display: block;
        }
    }

    &.-required {

        > input[type="checkbox"] {
            position: absolute;
            left: -1000000px;

            + label > .labelrequired,
            + input[type="hidden"] + label > .labelrequired {
                display: inline;
            }
        }
    }

    &.-warning {

        > input[type="checkbox"] + label > .labelbadge,
        > input[type="checkbox"] + input[type="hidden"] + label > .labelbadge {

            @if mixin-exists('labelbadge-warning') {
                display: block;
                @include labelbadge-warning();
            }
        }
    }

    &.-valid {

        > input[type="checkbox"] + label > .labelbadge,
        > input[type="checkbox"] + input[type="hidden"] + label > .labelbadge {

            @if mixin-exists('labelbadge-valid') {
                display: block;
                @include labelbadge-valid();
            }
        }
    }

    &.-error {

        > input[type="checkbox"] {

            + label,
            + input[type="hidden"] + label {

                .labelbadge {

                    @if mixin-exists('labelbadge-error') {
                        display: block;
                        @include labelbadge-error();
                    }
                }

                &::before {
                    border-color: $error-color;
                }
            }

            &:checked {

                + label::before,
                + input[type="hidden"] + label::before {
                    @include svg-icon('check-icon', $error-color);
                    background-size: 75%;
                }
            }
        }

        > .validationmessage {
            display: block;
        }
    }

    .checkboxtext {
        padding-right: 2.5em;

        @if function-exists(typeScale) {
            font-size: typeScale(1);
        }
    }

    &.-large {
        width: 100%;
    }
}

%checkbox-field {
    @include checkbox-field(
        $checkbox-field-default-color,
        $checkbox-field-outline-color,
        $checkbox-field-error-color,
        $checkbox-field-disabled-color
    );
}

@if $checkbox-field-export-class {

    @if str-length($checkbox-field-prefix) == 0 {

        .checkbox-field {
            @include checkbox-field(
                $checkbox-field-default-color,
                $checkbox-field-outline-color,
                $checkbox-field-error-color,
                $checkbox-field-disabled-color
            );
        }
    }
    @else {
        .#{$checkbox-field-prefix}-checkbox-field {

            @include checkbox-field(
                $checkbox-field-default-color,
                $checkbox-field-outline-color,
                $checkbox-field-error-color,
                $checkbox-field-disabled-color
            );
        }
    }
}
