﻿.arsv-auction-countdown {
    display: inline-block;
    min-width: 6em;
    font-size: typeScale(2);
    font-weight: 700;
    color: getColor(base, primary);
    vertical-align: middle;
    padding: 0 .5em;

    &::before {
        content: '';
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        @include svg-icon('hourglass-icon', getColor(text, secondary));
        vertical-align: middle;
        margin: 0 .25em .25em;
    }
}
