﻿$horizontal-menu-export-class: true !default;
$horizontal-menu-prefix: '' !default;
$horizontal-menu-color: #222 !default;
$horizontal-menu-standout: #666 !default;
$horizontal-menu-transition: 450ms !default;
$horizontal-menu-border: #ddd !default;

@mixin horizontal-menu($color: #222, $standout: #666, $transition: 450ms, $border: false) {
    text-transform: uppercase;
    user-select: none;

    > .content {
        list-style: none;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 1lh;

        > .item {

            > a,
            > .title {
                display: block;
                max-width: 23em;
                line-height: 1.5em;
                margin: 0;
                padding: .5em 1lh;
                border-bottom: 2px solid transparent;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 300;
                text-overflow: ellipsis;
                color: $color;
                outline: none;
                transition: color $transition, border-color $transition;

                &:active,
                &:focus,
                &:hover {
                    color: $standout;
                }
            }

            &:not(.-dropdown) {
                > a:active,
                > a:focus,
                > a:hover,
                > .title:active,
                > .title:focus,
                > .title:hover {
                    border-bottom-color: $standout;
                }
            }

            &.-current {

                & > a,
                > .title {
                    font-weight: 700;
                    color: $color;
                }

                &:not(.-dropdown) {
                    border-bottom-color: $standout;
                }
            }
        }
    }

    &.-border {
        @if type-of($border) == color {
            border-bottom: 1px solid getColor(base, lines);

            > .content {
                margin-bottom: -1px;
            }
        }
    }

    @media(--large) {

        > .content {
            margin: 0;
            padding: 0 1lh;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }

        &.-center {

            & > .content {
                justify-content: center;
            }
        }

        &.-right {

            & > .content {
                justify-content: flex-end;
            }
        }

        &.-justify {

            & > .content {
                justify-content: space-between;
            }
        }
    }
}

%horizontal-menu {
    @include horizontal-menu(
        $horizontal-menu-color,
        $horizontal-menu-standout,
        $horizontal-menu-transition,
        $horizontal-menu-border
    );
}

@if $horizontal-menu-export-class {

    @if str-length($horizontal-menu-prefix) == 0 {
        .horizontal-menu {
            @include horizontal-menu(
                $horizontal-menu-color,
                $horizontal-menu-standout,
                $horizontal-menu-transition,
                $horizontal-menu-border
            );
        }
    }
    @else {
        .#{$horizontal-menu-prefix}-horizontal-menu {
            @include horizontal-menu(
                $horizontal-menu-color,
                $horizontal-menu-standout,
                $horizontal-menu-transition,
                $horizontal-menu-border
            );
        }
    }
}
