@mixin svg-icon($name, $color: false, $size: contain, $pos: center center) {
  @if($color) {
    background-image: svg('#{$name}.svg', '[fill]: #{$color}');
  }
  @else {
    background-image: svg('#{$name}.svg');
  }
  background-size: $size;
  background-position: $pos;
  background-repeat: no-repeat;
}
