﻿$auction-description-export-class: true !default;
$auction-description-prefix: '' !default;
$auction-description-font-size-large: 1.5rem !default;
$auction-description-font-size-medium: 1.2rem !default;
$auction-description-font-size-small: 1rem !default;
$auction-description-max-width: 640px !default;
$auction-description-title-color: #666 !default;
$auction-description-text-color: #000 !default;

/**
 * @component auction-description
*/
@mixin auction-description($font-size: 1.5rem, $max-width: 640px, $title-color: #666, $text-color: #000) {
    width: 100%;
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-size: $font-size;
    color: $text-color;

    > .heading {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        /*height: 1em;*/
        margin-bottom: .25em;
        line-height: 1;
        white-space: normal;

        > .auctionsessions {
            display: inline-block;

            > .title {
                font-weight: 400;
                white-space: nowrap;
                padding-right: 1.5em;
                color: $text-color;

                @if mixin-exists('svg-icon') {
                    @include svg-icon('chevron-down-icon', getColor(base, primary), .75em, right .25em center);
                }

                &.-single {
                    padding-right: 0;
                    background-image: none;
                    cursor: default;
                }
            }

            > .content {
                z-index: 3;
                width: 92vw;
                min-width: unset;
                max-width: 640px;
                border-top: 1px solid getColor(base, lines);
                padding: .25em;

                > li {
                    white-space: normal;
                }
            }
        }
    }

    > .title {
        min-height: 1em;
        margin: 0 0 .25em 0;
        font-size: 1em;
        font-weight: 700;
        white-space: normal;
        text-transform: uppercase;
        color: $title-color;

        > span,
        > .label,
        > a > span,
        > a > .label {
            line-height: 1;
            vertical-align: middle;
        }
    }

    > .content {
        margin-bottom: .125em;
        line-height: 1;
    }

    .auctiondate,
    .auctionhouse,
    .auctionsessions > .title {
        font-size: 83.333%;
    }

    .auctiondate {
        font-weight: 300;
        letter-spacing: .01em;
        text-transform: capitalize;

        > .hours {
            text-transform: none;
        }
    }

    .auctionhouse {
        font-weight: 300;
        user-select: none;

        > a {
            line-height: 1;
            vertical-align: middle;
            color: $text-color;
            outline: none;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
            }
        }
    }

    .auctionabstract {
        font-size: 83.33%;
        line-height: 1.5;
    }

    > .content > .sessions {
        padding: 0;
        margin: .75em 0 1em;
        max-width: none;
        font-size: typeScale(2);


        > .content > .item > .content {
            padding: 0;

            > ul {
                list-style-type: disc;
                font-size: 1rem;
                margin: 0 0 .5em 0;
                padding-left: 1.5em;
                line-height: 1.75;
                font-weight: 400;

                &.auctionsessions {
                    list-style: none;
                    padding: 0;
                }

                > li > strong {
                    font-weight: 700;
                }
            }

            > ol {
                font-size: 1rem;
                line-height: 1.5;
                margin: 0 0 .5em 0;
                padding-left: .5em;
                font-weight: 400;
            }

            > p {
                margin: 0 0 .5em 0;
                line-height: 1.25;
                font-weight: 400;
            }
        }
    }
}

%auction-description-small {
    @include auction-description(
        $auction-description-font-size-small,
        $auction-description-max-width,
        $auction-description-title-color,
        $auction-description-text-color
    );

    .auctionabstract {
        font-size: 100%;
    }
}

%auction-description-medium {
    @include auction-description(
        $auction-description-font-size-medium,
        $auction-description-max-width,
        $auction-description-title-color,
        $auction-description-text-color
    );
}

%auction-description-large {
    @include auction-description(
        $auction-description-font-size-large,
        $auction-description-max-width,
        $auction-description-title-color,
        $auction-description-text-color
    );

    max-height: none;

    .auctioncurrent,
    .auctiondate,
    .auctionhouse {
        font-size: 65%;
    }
}

@if $auction-description-export-class {

    @if str-length($auction-description-prefix) == 0 {

        .auction-description {
            @include auction-description( $auction-description-font-size-medium, $auction-description-max-width, $auction-description-title-color, $auction-description-text-color );

            &.-small {
                font-size: $auction-description-font-size-small;

                .auctionabstract {
                    font-size: 100%;
                }
            }

            &.-large {
                max-height: none;
                font-size: $auction-description-font-size-large;

                .auctioncurrent,
                .auctiondate,
                .auctionhouse {
                    font-size: 65%;
                }

                .auctionabstract {
                    font-size: 70%;
                }
            }
        }
    }
    @else {

        .#{$auction-description-prefix}-auction-description {
            @include auction-description( $auction-description-font-size-medium, $auction-description-max-width, $auction-description-title-color, $auction-description-text-color );

            &.-small {
                font-size: $auction-description-font-size-small;

                .auctionabstract {
                    font-size: 100%;
                }
            }

            &.-large {
                max-height: none;
                font-size: $auction-description-font-size-large;

                .auctioncurrent,
                .auctionnumber,
                .auctiondate,
                .auctionhouse {
                    font-size: 65%;
                }

                .auctionabstract {
                    font-size: 70%;
                }
            }
        }
    }
}
