﻿.arsv-dropdown-menu {
    @extend %vertical-menu;
    position: relative;
    width: auto;
    padding: 0;

    > span,
    > .title {
        cursor: pointer;

        &:hover {
            border-bottom-color: transparent;
        }
    }

    > .content {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        min-width: 20em;
        margin: -1px 0 0 -1px;
        padding: .25lh .75lh;
        background-color: getColor(background, body);
        border: 1px solid getColor(base, lines);
        border-top: 0;

        > .item {
            white-space: nowrap;
        }
    }

    &:hover {

        > span,
        > .title {
            border-bottom-color: transparent;
        }

        > .content {
            display: block;
        }
    }

    &.-right {

        > .content {
            left: auto;
            right: 0;
            margin: -1px -1px 0 0;
        }
    }
}
