﻿$lot-item-export-class: true !default;
$lot-item-prefix: '' !default;
$lot-item-breakpoint: 960px !default;
$lot-item-thumb-size: 200px !default;

@mixin lot-item($breakpoint, $thumb-max-size) {
    $mobile-width: 95vw;
    $thumb-size: $thumb-max-size/$breakpoint*100vw;
    $thumb-height: 250px;
    $figcaption-height: 30px;
    $padding: 6px;

    position: relative;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > figure {
        position: relative;
        margin: 0;
        width: 100%;
        user-select: none;

        > figcaption {
            height: $figcaption-height;
            padding-bottom: $padding;
        }

        > a {
            position: relative;
            display: block;
            width: 100%;
            max-height: $thumb-height;

            > img {
                width: 100%;
                // https://github.com/fregante/object-fit-images
                @if mixin-exists('object-fit') {
                    @include object-fit('contain');
                    object-fit: contain;
                }
                @else {
                    object-fit: contain;
                }

                &:not([src]) {
                    display: none;
                }
            }
        }
    }

    > section {
        position: relative;
        flex: 1 1 290px;
        max-width: 100%;
        height: $thumb-max-size + $figcaption-height + $padding;
        margin-bottom: 1rem;
        overflow-y: hidden;
        line-height: 1.5;

        > .lotnumber {
            display: block;
        }

        > h3 {
            font-size: typeScale(2);
            margin: 0;
        }

        > p {
            font-size: typeScale(2);
            margin: .25em 0 0 0;
        }

        > ul,
        > ol {
            font-size: typeScale(2);
        }

        > ul {
            list-style-type: disc;
        }

        > ol {
            padding-left: 0;
        }

        &:not(.-has-title) {

            > h3 {
                display: inline;

                + p {
                    display: inline;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3em;
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)
        }
    }

    > aside {
        flex: 1 1 240px;
        text-align: right;


        > .status {
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 1em;
            background-color: transparent;

            > .content,
            > .footer {
                flex: 1 1 auto;
                min-width: 200px;
            }

            > .content > .price {
                width: 100%;

                > tbody {

                    > tr > th {
                        text-align: right;
                    }

                    > tr > .price {
                        text-align: right;
                        font-size: typeScale(3);
                    }
                }

                > tfoot {
                    text-align: right;
                    background-color: transparent;
                }
            }

            > .footer {
                display: flex;
                flex-wrap: wrap;
                text-align: right;
                flex-direction: column;
                justify-content: flex-start;
                border: 0;

                > .estimate,
                > .baseprice {
                    font-size: typeScale(1);
                    display: block;
                    white-space: nowrap;
                    flex: 0 0 auto;
                }
            }
        }

        > .buttons {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            width: 100%;

            > .follow,
            > .detail {
                flex: 1 1 auto;
                min-width: 200px;
                padding: $padding 0;
            }

            > .detail > a {
                width: 135px;
            }
        }
    }

    @media(max-width: 765px) {

        > aside {
            text-align: left;

            > .status > .content > .price > tbody > tr > th,
            > .status > .content > .price > tbody > tr > .price {
                text-align: left;
            }

            > .status > .content > .price > tfoot {
                text-align: left;
            }

            > .status > .footer {
                align-items: flex-start;
            }
        }
    }

    @media(max-width: 715px) {

        > figure {
            flex: 1 1 100%;
            max-width: none;
            max-height: none;
            margin-bottom: 1rem;

            > a {

                > img {
                    width: 100%;
                    max-height: $thumb-max-size;
                }
            }
        }

        > section {
            flex: 1 1 100%;
        }
    }

    @media(min-width: 716px) {

        > figure {
            flex: 0 1 $thumb-max-size + $padding*2;
            padding-right: $padding*2;

            > a {
                height: $thumb-height;

                > img {
                    height: 100%;
                    height: 100%;
                }
            }
        }

        > section {
            max-width: 480px;
        }
    }

    @media(min-width: 1100px) {

        > aside {
            flex: 0 1 240px;
        }
    }
}

%lot-item {
    @include lot-item($lot-item-breakpoint, $lot-item-thumb-size);
}

@if $lot-item-export-class {

    @if str-length($lot-item-prefix) == 0 {

        .lot-item {
            @include lot-item($lot-item-breakpoint, $lot-item-thumb-size);
        }
    }
    @else {

        .#{$lot-item-prefix}-lot-item {
            @include lot-item($lot-item-breakpoint, $lot-item-thumb-size);
        }
    }
}

