﻿$lot-price-export-class: true !default;
$lot-price-prefix: '' !default;
$lot-price-color: #666 !default;

@mixin lot-price($color: #666) {

    > tbody > tr > th {
        padding: 0;
        line-height: 1;
        text-transform: uppercase;
        font-weight: 400;
        user-select: none;
        text-align: left;

        @if function-exists('typeScale') {
            font-size: typeScale(1);
        }
    }

    > tbody > tr > .price {
        padding: .25em 0;
        font-weight: 700;
        line-height: 1;

        @if function-exists('typeScale') {
            font-size: typeScale(4);
        }

        &.-start:not(.-offers-0):not(.-presales-free-offer) {
            color: $color;
            text-decoration: line-through;
            user-select: none;
        }

        &.-start.-lot-sellable:not(.-offers-0):not(.-presales-free-offer)::after,
        &.-start.-lot-sold:not(.-offers-0):not(.-presales-free-offer)::after {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 1em;
            height: .5em;

            @if mixin-exists('svg-icon') {
                @include svg-icon('arrow-right-icon', $color);
            }
        }
    }

    > tfoot {
        background-color: transparent;
    }

    > tfoot > tr > .offers {
        padding: 0 0 .5em 0;
        line-height: 1;

        @if function-exists('typeScale') {
            font-size: typeScale(1);
        }
    }

    @media(min-width: 480px) {
        > tbody > tr > .price {
            @if function-exists('typeScale') {
                font-size: typeScale(5);
            }
        }
    }
}

%lot-price {
    @include lot-price($lot-price-color);
}

@if $lot-price-export-class {

    @if str-length($lot-price-prefix) == 0 {
        .lot-price {
            @include lot-price($lot-price-color);
        }
    }
    @else {
        .#{$lot-price-prefix}-lot-price {
            @include lot-price($lot-price-color);
        }
    }
}
