﻿$textarea-field-export-class: true !default;
$textarea-field-prefix: '' !default;
$textarea-field-font: sans-serif !default;
$textarea-field-default-color: #ddd !default;
$textarea-field-outline-color: #00f !default;
$textarea-field-error-color: #900 !default;
$textarea-field-disabled-color: #999 !default;

@mixin textarea-field($font: sans-serif, $default-color: #ddd, $outline-color: #00f, $error-color: #900, $disabled-color: #999) {
    position: relative;
    padding: .5em;

    > label {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: .5em;

        > .labelrequired {
            color: $error-color;
        }

        > .labelbadge {

            @if mixin-exists('labelbadge') {
                @include labelbadge();
                position: relative;
                margin-left: 1em;
                margin-bottom: -6px;
                display: none;
            }
        }
    }

    > textarea {
        resize: none;
        padding: .25em .5em;
        font-family: $font;
        line-height: 1.5;
        border: 1px solid $default-color;

        &:not([disabled]):not([readonly]):active,
        &:not([disabled]):not([readonly]):focus {
            border-color: $outline-color;
        }
    }

    > .validationmessage {

        @if mixin-existsvalidationmessage {
            @include validationmessage();
        }
    }

    &:not(.-required) > label > .labelrequired {
        display: none;
    }

    &.-warning > label > .labelbadge {

        @if mixin-exists('labelbadge-warning') {
            display: inline-block;
            @include labelbadge-warning();
        }
    }

    &.-valid > label > .labelbadge {

        @if mixin-exists('labelbadge-valid') {
            display: inline-block;
            @include labelbadge-valid();
        }
    }

    &.-error {

        > label > .labelbadge {

            @if mixin-exists('labelbadge-error') {
                display: inline-block;
                @include labelbadge-error();
            }
        }

        > textarea:not([disabled]):not([readonly]),
        > textarea:not([disabled]):not([readonly]):active,
        > textarea:not([disabled]):not([readonly]):focus {
            border-color: $error-color;
        }

        > textarea:not([disabled]):not([readonly])::placeholder {
            color: $error-color;
        }

        > .validationmessage {
            display: block;
            position: relative;
        }
    }
}

%textarea-field {
    @include textarea-field();
}

@if $textarea-field-export-class {

    @if str-length($textarea-field-prefix) == 0 {
        .textarea-field {
            @include textarea-field();
        }
    }
    @else {
        .#{$textarea-field-prefix}-textarea-field {
            @include textarea-field();
        }
    }
}
