﻿.avcb-footer-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    padding: .5lh;
    user-select: none;

    &::after {
        content: '';
        display: table;
        width: 100%;
        height: 0;
        clear: both;
    }


    &.-custom-html {
        padding: 0;
    }

    &.-empty-website {
        background-color: getColor(background, light);
        color: getColor(base, primary);
    }

    > .content {
        max-width: $container-width;
        margin: 0 auto;
        text-align: center;
    }
}
