﻿.avcb-button-link.-transparent {
    color: getColor(base, primary);
    background-color: transparent;

    &::before {
        background-color: getColor(base, primary);
    }

    &:hover,
    &:active,
    &:focus {
        color: getColor(text, inverted);
    }
}
