﻿$button-link-export-class: true !default;
$button-link-prefix: '' !default;
$button-link-font: sans-serif !default;
$button-link-color: #fff !default;
$button-link-background: #000 !default;
$button-link-border: false !default;
$button-link-transition: false !default;

@mixin button-link($font: sans-serif, $color: #fff, $background: #000, $border: false, $transition: false) {

    $default-transition: 250ms;

    display: inline-block;
    position: relative;
    line-height: 1.75em;
    height: 3em;
    padding: .5em 1.5em;
    max-width: 30em;

    @if type-of($border) == color {
        border: 2px solid $border;
    }
    @else {
        border: 2px solid $background;
    }

    text-align: center;
    font-size: 90%;
    font-family: $font;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $background;
    color: $color;
    overflow: hidden;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    outline: none;

    > span {
        display: inline-block;
        position: relative;
        z-index: 1;
        font-size: 1em;
        line-height: 1.5;
        white-space: nowrap;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        width: 150%;
        height: 104%;
        background-color: $color;
        transform: skew(45deg) scale3d(0,1,0);
        transform-origin: bottom left;

        @if type-of($transition) == number {
            transition: transform $transition;
        }
        @else {
            transition: transform $default-transition;
        }
    }

    &:hover,
    &:active,
    &:focus {
        color: $background;

        &::before {
            transform: skew(45deg) scale3d(1,1,1);
        }
    }
}

%button-link {
    @include button-link(
        $button-link-font,
        $button-link-color,
        $button-link-background,
        $button-link-border,
        $button-link-transition
    );
}

@if $button-link-export-class {

    @if str-length($button-link-prefix) == 0 {
        .button-link {
            @include button-link(
                $button-link-font,
                $button-link-color,
                $button-link-background,
                $button-link-border,
                $button-link-transition
            );
        }
    }
    @else {
        .#{$button-link-prefix}-button-link {
            @include button-link(
                $button-link-font,
                $button-link-color,
                $button-link-background,
                $button-link-border,
                $button-link-transition
            );
        }
    }
}
