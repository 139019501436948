﻿.avcb-horizontal-menu.-lot-navigation {
    text-transform: none;


    > .content {
        flex-direction: row;
        padding: 0;
        margin-bottom: .5em;

        > .item {
            flex: 1 1 33.32%;
            text-align: center;
        }

        > .item > .prevlot,
        > .item > .nextlot {
            padding: 0;
            border: 0;

            > svg {
                display: inline-block;
                vertical-align: middle;
                width: 20px;
                height: 20px;
            }

            > span {
                vertical-align: middle;
            }

            &:hover,
            &:focus,
            &:active {
                border-bottom-color: transparent;
            }
        }

        > .item > .prevlot {
            padding-left: 0;
            text-align: left;
        }

        > .item > .nextlot {
            padding-right: 0;
            text-align: right;
        }
    }
}
