﻿$input-field-export-class: true !default;
$input-field-prefix: '' !default;
$input-field-border-color: #999 !default;
$input-field-error-color: #900 !default;
$input-field-disabled-color: #eee !default;

@mixin input-field($border-color: #999, $error-color: #900, $disabled-color: #eee) {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    > label {
        @if mixin-exists('field-label') {
            @include field-label();
        }

        > .labelrequired {
            color: $error-color;
        }

        > .labelbadge {
            @if mixin-exists('labelbadge') {
                @include labelbadge();
            }

            position: absolute;
            display: none;
            right: 1em;
            top: 50%;
            margin-top: -11px;
        }
    }

    > input:not([type="submit"]) {
        position: relative;
        flex: 1;
        height: 1.25lh;
        margin-right: .5em;
        padding-left: 0;
        border: 0;
        border-bottom: 1px solid $border-color;

        &.input-validation-error {
            border-bottom-color: $error-color;

            &::placeholder {
                color: $error-color;
                opacity: 1;
            }
        }

        &[disabled],
        &[readonly] {
            background-color: lighten($disabled-color, 2%);
            color: darken($disabled-color, 25%);
            border-color: transparent;
            user-select: none;
            padding-left: .5em;
        }

        &:not([disabled]):not([readonly]):focus,
        &:not([disabled]):not([readonly]):active {
            padding-left: .5em;
        }
    }

    > input[type="date"] {

        @if variable-exists('font-primary') {
            font-family: $font-primary;
        }

        text-transform: uppercase;
    }

    > input[type="time"] {

        @if variable-exists('font-primary') {
            font-family: $font-primary;
        }
    }

    > .validationmessage {
        @if mixin-exists('validationmessage') {
            @include validationmessage();
        }
    }

    &:not(.-required) {

        > label > .labelrequired {
            display: none;
        }
    }

    &.-valid > label > .labelbadge {
        display: block;

        @if mixin-exists('labelbadge-valid') {
            @include labelbadge-valid();
        }
    }

    &.-warning > label > .labelbadge {
        display: block;

        @if mixin-exists('labelbadge-warning') {
            @include labelbadge-warning();
        }
    }

    &.-error {

        > label > .labelbadge {
            display: block;

            @if mixin-exists('labelbadge-error') {
                @include labelbadge-error();
            }
        }

        > input:not([type="submit"]):not([type="range"]):not([disabled]):not([readonly]) {
            border-color: $error-color;

            &::placeholder {
                color: $error-color;
            }

            &:focus,
            &:active {
                border-color: $error-color;
                border-bottom-color: $error-color;
            }
        }

        > .validationmessage {
            display: block;
            position: relative;
            line-height: 1.1;
            bottom: 0;
            right: 0;
        }
    }

    &.-small {
        max-width: 13.5em;

        > label {
            text-align: right;
        }

        > input:not([type="submit"]) {
            max-width: 6em;
        }
    }
}

%input-field {
    @include input-field($input-field-border-color, $input-field-error-color);
}

@if $input-field-export-class {

    @if str-length($input-field-prefix) == 0 {
        .input-field {
            @include input-field($input-field-border-color, $input-field-error-color);
        }
    }
    @else {
        .#{$input-field-prefix}-input-field {
            @include input-field($input-field-border-color, $input-field-error-color);
        }
    }
}
