﻿.avcb-header-wrapper {
    position: relative;
    width: 100%;
    margin: 0;
    padding: .5lh;
    user-select: none;

    &::after {
        content: '';
        display: table;
        width: 100%;
        height: 0;
        clear: both;
    }

    &.-custom-html {
        margin: 0;
        padding: 0;
    }

    &.-empty-website {
        background-color: getColor(base, primary);
        color: getColor(text, inverted);
        max-height: 80px;

        > .content > h1 {
            color: getColor(text, inverted);
        }
    }

    &:not(.-custom-html) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        width: 100vw;
        height: 64px;
        box-shadow: 0 0 10px rgba(0,0,0,.25);

        + * {
            margin-top: 64px !important;
        }
    }

    > .content {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        max-width: $container-width;
        margin: 0 auto;

        > .logo {
            flex: 0 0 200px;
            position: relative;
            z-index: 60;
            max-width: 200px;

            > img {
                position: relative;
                max-width: 100%;
                max-height: 100%;
            }
        }


        > h1 {
            flex: 1 1 auto;
            margin: 0;
            text-align: right;
            font-weight: 400;
            font-size: typeScale(3);
        }

        > .headermenu {

            > i {
                display: none;
            }

            > .content > .item > a {
                padding: .5em 1em;
            }

            > .content > .item > a:hover,
            > .content > .item > a:focus,
            > .content > .item > a:active,
            > .content > .item.-current > a {
                border-color: transparent;
            }
        }
    }

    @media (max-width: 960px) {

        &:not(.-custom-html) {

            > .content {
                background-color: inherit;

                > h1 {
                    display: none;
                }

                > .headermenu {
                    background-color: inherit;

                    > i {
                        position: relative;
                        z-index: 55;
                        display: block;
                        flex: 0 0 64px;
                        width: 64px;
                        height: 64px;
                        color: inherit;

                        > svg {
                            width: 32px;
                            height: 32px;
                            margin: 16px;
                        }
                    }

                    > .content {
                        position: fixed;
                        top: 0;
                        left: 0;
                        height: 100vh;
                        width: 100vw;
                        padding: 1em 0;
                        background-color: inherit;
                        transition: transform 400ms;
                    }

                    &:not(.-open) {

                        > i > svg > .menu-close {
                            display: none;
                        }

                        > .content {
                            transform: translateX(100vw);
                        }
                    }

                    &.-open {

                        > i > svg > .menu-open {
                            display: none;
                        }

                        > .content {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }
}
