﻿$vertical-menu-export-class: true !default;
$vertical-menu-prefix: '' !default;
$vertical-menu-font-size: 1rem !default;
$vertical-menu-color: #000 !default;
$vertical-menu-standout: #666 !default;
$vertical-menu-border: #999 !default
$vertical-menu-max-width: 400px !default;

@mixin vertical-menu($font-size: 1rem, $color: false, $standout: false, $border: false, $max-width: false) {
    width: 100%;
    padding: $font-size*.75 $font-size*1.5;
    user-select: none;

    @if type-of($max-width) == number {
        max-width: $max-width;
    }

    > .title {
        font-size: $font-size;
        font-weight: 700;
        margin: 0 0 .5em 0;
    }

    > .content {
        list-style: none;
        padding: 0;

        > .item {
            font-weight: 300;
            line-height: 1;

            @if type-of($border) == color {
                border-top: 1px dashed $border;
            }

            &:first-child {
                border-top: 0;
            }

            &.-current {
                font-weight: 700;

                > a {
                    @if type-of($color) == color {
                        color: $color;

                        &:hover,
                        &:focus,
                        &:active {
                            color: $color;
                        }
                    }
                }
            }

            &.-separator {
                padding-bottom: $font-size*1.5;
            }

            &.-standout {
                font-weight: 700;
                text-transform: uppercase;
            }

            &.-no-border {
                border-top: 0;
            }

            > a {
                display: block;
                padding: $font-size*.75 0;
                outline: none;

                @if type-of($color) == color {
                    color: $color;
                }

                &:hover,
                &:active,
                &:focus {

                    @if type-of($standout) == color {
                        color: $standout;
                    }
                }
            }
        }
    }

    &.-cozy {

        > .content {

            > .item {

                > a {
                    padding: .5em 0;
                }
            }
        }
    }

    &.-no-padding {
        padding: 0;
    }

    &.-solid-border {

        > .content {

            > .item {
                border-top-style: solid;
            }
        }
    }
}

%vertical-menu {

    @include vertical-menu( 
        $vertical-menu-font-size, 
        $vertical-menu-color, 
        $vertical-menu-standout, 
        $vertical-menu-border, 
        $vertical-menu-max-width
    );
}


@if $vertical-menu-export-class {

    @if str-length($vertical-menu-prefix) == 0 {

        .vertical-menu {
            @include vertical-menu( 
                $vertical-menu-font-size, 
                $vertical-menu-color, 
                $vertical-menu-standout, 
                $vertical-menu-border, 
                $vertical-menu-max-width
            );
        }
    }
    @else {
        .#{$vertical-menu-prefix}-vertical-menu {
            @include vertical-menu( 
                $vertical-menu-font-size, 
                $vertical-menu-color, 
                $vertical-menu-standout, 
                $vertical-menu-border, 
                $vertical-menu-max-width
            );
        }
    }
}
