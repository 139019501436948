﻿.arsv-auction-badge {
    position: relative;
    display: inline-block;
    height: 3.5em;
    padding: .35em .5em;
    border-radius: 1.75em;
    font-size: 65%;
    font-style: normal;
    letter-spacing: 1px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.3;
    text-align: center;
    user-select: none;

    &.-liveauction {
        padding-left: 2em;
        color: getColor(state, error);
        background-color: transparent;

        &::before {
            content: '';
            position: absolute;
            left: .25em;
            top: 50%;
            margin-top: -1em;
            display: block;
            width: 2em;
            height: 2em;
        }

        &.-audio {

            &::before {
                @include svg-icon('mic-icon', getColor(state, error));
            }
        }

        &.-video {

            &::before {
                @include svg-icon('camera-icon', getColor(state, error));
            }
        }

        &.-automatic {

            &::before {
                @include svg-icon('screen-icon', getColor(state, error));
            }
        }

        &.-it {
            width: 10.5em;
        }

        &.-en {
            width: 10.5em;
        }
    }
}
