﻿$state-label-export-class: true !default;
$state-label-prefix: '' !default;
$state-label-font-size: 10px !default;
$state-label-background: #666 !default;
$state-label-color: #fff !default;
$state-label-states: (
    "muted":   #eee,
    "success": #10a887,
    "warning": #F17F42,
    "error":   #da3c3c
) !default;

@mixin state-label($font-size: 10px, $background: #666, $color: #fff, $states: false) {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    padding: $font-size*.2 $font-size*.5;
    font-size: $font-size;
    line-height: 1.1;
    letter-spacing: .5px;
    height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    color: $color;
    background-color: $background;
    user-select: none;

    @if type-of($states) == map {

        @each $s, $c in $states {

            &.-#{$s} {
                background-color: $c;
            }
        }
    }
}

%state-label {
    @include state-label(
        $state-label-font-size, 
        $state-label-background,
        $state-label-color,
        $state-label-states
    );
}

@if $state-label-export-class {

    @if str-length($state-label-prefix) == 0 {

        .state-label {
            @include state-label(
                $state-label-font-size, 
                $state-label-background,
                $state-label-color,
                $state-label-states
            );
        }
    }
    @else {
        .#{$state-label-prefix}-state-label {
            @include state-label(
                $state-label-font-size, 
                $state-label-background,
                $state-label-color,
                $state-label-states
            );
        }
    }
}
