﻿$alert-message-export-class: true !default;
$alert-message-prefix: '' !default;
$alert-message-default-color: #999 !default;
$alert-message-success-color: #090 !default;
$alert-message-error-color: #900 !default;
$alert-message-warning-color: #990 !default
$alert-message-transition: 450ms !default;

@mixin alert-message($default-color: #999, $success-color: #090, $error-color: #900, $warning-color: #990, $transition: 450ms) {
    position: relative;
    border: 1px solid $default-color;
    color: $default-color;
    background-color: lighten($default-color, 50%);
    user-select: none;
    transform: translateX(0);
    opacity: 1;

    @if variable-exists('gutter') {
        padding: $gutter*.5 $gutter*.75;
    }

    @if function-exists(typeScale) {
        font-size: typeScale(1);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1rem;
        height: 1rem;
        border-left: 1rem solid $default-color;
        border-bottom: 1rem solid transparent;
        display: block;
    }

    a {
        font-weight: 700;
        color: $default-color;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }

    > ul {
        list-style: none;
        padding: 0;
        margin: 0;
        color: $default-color;

        > li {
            color: $default-color;
        }
    }

    > .dismissalert {
        display: block;
        position: absolute;
        top: 2px;
        right: 5px;
        z-index: 5;
        width: .5lh;
        height: .5lh;
        padding: 0;
        margin: 0;
        font-size: 150%;
        font-weight: 700;
        line-height: .75;
        background-color: transparent;
        apperarance: none;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }

    > .title {
        display: block;
        margin: 0 0 .25em 0;
        padding: 0;
        font-size: 1em;
        color: $default-color;
    }

    &.-fade {
        transition: opacity, $transition;
        opacity: 0;
    }

    &.-dismissleft {
        transition: transform $transition*2, opacity $transition*2;
        transform: translateX(120vw);
        opacity: 0;
    }

    &.-dismissright {
        transition: transform $transition*2, opacity $transition*2;
        transform: translateX(-120vw);
        opacity: 0;
    }

    &.-hidden {
        display: none;
    }

    &.-success {
        color: $success-color;
        border-color: $success-color;
        background-color: lighten($success-color, 50%);

        a {
            color: $success-color;
        }

        > .title {
            color: $success-color;
        }

        &::before {
            border-left-color: $success-color;
        }

        > ul {
            color: $success-color;

            > li {
                color: $success-color;
            }
        }
    }

    &.-error {
        color: $error-color;
        border-color: $error-color;
        background-color: lighten($error-color, 35%);

        a {
            color: $error-color;
        }

        > .title {
            color: $error-color;
        }

        &::before {
            border-left-color: $error-color;
        }

        > ul {
            color: $error-color;

            > li {
                color: $error-color;
            }
        }
    }

    &.-warning {
        color: $warning-color;
        border-color: $warning-color;
        background-color: lighten($warning-color, 35%);

        a {
            color: $warning-color;
        }

        > .title {
            color: $warning-color;
        }

        &::before {
            border-left-color: $warning-color;
        }

        > ul {
            color: $warning-color;

            > li {
                color: $warning-color;
            }
        }
    }
}

%alert-message {
    @include alert-message(
        $alert-message-default-color, 
        $alert-message-success-color,
        $alert-message-error-color, 
        $alert-message-warning-color,
        $alert-message-transition
    );
}

@if $alert-message-export-class {

    @if str-length($alert-message-prefix) == 0 {
        .alert-message {
            @include alert-message(
                $alert-message-default-color,
                $alert-message-success-color,
                $alert-message-error-color,
                $alert-message-warning-color,
                $alert-message-transition
            );
        }
    }
    @else {
        .#{$alert-message-prefix}-alert-message {
            @include alert-message(
                $alert-message-default-color, 
                $alert-message-success-color,
                $alert-message-error-color, 
                $alert-message-warning-color,
                $alert-message-transition
            );
        }
    }
}
