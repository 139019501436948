﻿$lot-status-export-class: true !default;
$lot-status-prefix: '' !default;
$lot-status-background: #eee !default;
$lot-status-border: #999 !default;
$lot-status-color: #222 !default;
$lot-status-inverted-color: #fff !default

@mixin lot-status($background: #eee, $border: #999, $color: #222, $inverted-color: #fff) {
    padding: .75rem;
    background-color: $background;

    > .content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;
        padding: .25em 0;

        > .lotprice {
            max-width: 220px;
        }

        > .waittime {

            @if function-exists('typeScale') {
                font-size: typeScale(2);
            }

            font-weight: 700;
            color: $color;
            vertical-align: middle;
            padding: 0 .5em;

            @if mixin-exists('svg-icon') {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 1.5em;
                    height: 1.5em;
                    margin: 0 .25em .25em;
                    vertical-align: middle;
                    @include svg-icon('hourglass-icon', $color);
                }
            }
        }
    }

    > .actions {
        padding: .5rem 0 1rem;

        > .lotcta {
            display: block;
            margin: 0;
            text-transform: uppercase;
            text-align: right;
            text-decoration: underline;

            @if function-exists('typeScale') {
                font-size: typeScale(2);
            }
        }

        > .placebid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }

        > .placebid > .bid {
            flex: 1 auto;
            padding: 0;
        }

        > .placebid > .bid > label {
            display: none !important;
        }

        > .placebid > .bid > select {
            width: 100%;
            max-width: none;
            height: 2.75rem;

            @if function-exists('typeScale') {
                font-size: typeScale(3);
            }
        }

        > .placebid > .bid > select:not([disabled]):not([readonly]):not(:focus):not(:active) {
            background-color: $inverted-color;
        }

        > .placebid > .bid > input {
            width: 100%;
            border: 1px solid $border;
            height: 2.75rem;
            text-align: right;

            @if function-exists('typeScale') {
                font-size: typeScale(3);
            }
        }

        > .placebid > .bid > input:not([disabled]):not([readonly]):not(:focus):not(:active) {
            background-color: $inverted-color;
        }

        > .placebid > .bid > .decimals {
            display: inline-block;
            padding-left: .25em;
            vertical-align: middle;
            font-weight: 700;
            user-select: none;
        }

        > .placebid > .button {
            width: 100%;
            margin-top: 1.5rem;
            min-width: 12em;
        }
    }

    > .footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: .5em;
        border-top: 1px solid $border;

        @if function-exists('typeScale') {
            font-size: typeScale(2);
        }

        > .estimate {
            flex: 0 0 50%;
        }

        > .baseprice {
            flex: 1 1 100%;
        }

        > .estimate > .estimatelabel {
            text-transform: uppercase;
            user-select: none;
        }

        > a {
            text-decoration: underline;
            user-select: none;
        }
    }

    @media(--small) {

        > .actions > .placebid {
            flex-wrap: nowrap;

            > .bid {
                width: auto;
                padding-right: 1em;
            }

            > .bid > select {
                width: auto;
            }

            > .button {
                width: auto;
                margin-top: 0;
            }
        }
    }
}

%lot-status {
    @include lot-status(
        $lot-status-background,
        $lot-status-border,
        $lot-status-color,
        $lot-status-inverted-color
    );
}

@if $lot-status-export-class {

    @if str-length($lot-status-prefix) == 0 {
        .lot-status {
            @include lot-status(
                $lot-status-background,
                $lot-status-border,
                $lot-status-color,
                $lot-status-inverted-color
            );
        }
    }
    @else {
        .#{$lot-status-prefix}-lot-status {
            @include lot-status(
                $lot-status-background,
                $lot-status-border,
                $lot-status-color,
                $lot-status-inverted-color
            );
        }
    }
}
