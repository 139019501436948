﻿$auction-detail-export-class: true !default;
$auction-detail-prefix: '' !default;
$auction-detail-max-width: 1200px !default;

@mixin auction-detail($max-width: 1200px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
    margin: 0 auto;
    max-width: $max-width;

    &.-ie11 {

        > .auctionlots {
            width: 100%;
        }
    }

    > .images {
        display: none;
    }

    > .description {
        flex: 1 1 100%;
        padding: .5lh;


        > .buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            > * {
                flex: 0 1 33%;
            }

            > .details {
                appearance: none;
                color: getColor(base, primary);
                background-color: transparent;
                display: inline;
                text-decoration: underline;
                padding: 0;
                text-align: left;
                font-size: typeScale(2);
                cursor: pointer;
            }

            > .actionbutton {
                flex: 1 1 50%;
                font-size: typeScale(1);
            }
        }
    }

    > .auctionlots {
        padding: 0 .5lh;

        > .listsettings {
            margin: .5lh -.5lh;
        }

        > .list {

            > .item {

                > article {

                    > .description {
                        max-width: none;
                    }
                }
            }
        }
    }

    @media(--small) {
        align-items: stretch;

        > .images {
            position: relative;
            display: block;
            flex: 1 1 50%;
            padding: .5lh;

            > div > .imagelarge,
            > div > .imagesmall {
                box-shadow: 0 0 1px rgba(0,0,0,.25);
            }
        }

        > .description {
            flex: 1 1 50%;

            > .buttons {
                flex: 1 1 auto;
                flex-direction: row-reverse;
                justify-content: flex-start;
                align-items: flex-end;

                > * {
                    flex: 0 0 auto;
                }

                > .details {
                    flex: 1 1 100%;
                }

                > .actionbutton {
                    flex: 0 0 auto;

                    &:not(:first-child) {
                        margin-left: .5lh;
                    }
                }
            }
        }
    }

    @media(--large) {

        > .images {
            flex: 1 1 40%;
            max-width: 350px;
        }

        > .description {
            flex: 1 1 60%;
            max-width: none;

            > .buttons {

                > .actionbutton {
                    font-size: typeScale(2);
                }
            }
        }
    }
    /*> .images,
    > .description,
    > .actions,
    > .auctionlots {
        flex: 1 1 100%;
    }

    > .images {

    }

    > .description {
        padding: .5lh;

        > .content > .auctionhouse > br {
            display: none;
        }

        > .content > .auctionhouse > span {
            padding-left: .5em;
            vertical-align: middle;
        }

        > .content > .actions {
            text-align: right;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
        }

        > .content > .sessions > ul > li > div > p > a {
            text-decoration: underline;
        }
    }

    > .auctionlots {
        margin-top: 1lh;

        > .list > li > article > section {
            max-width: none;
        }
    }

    @media(--large) {
        padding: 1lh 0;

        > .images {
            flex: 0 1 30%;
            padding: 0 .5lh;
        }

        > .description {
            flex: 0 1 70%;
            padding: 0 .5lh;
            max-width: none;
        }

        > .actions {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            justify-content: space-between;

            > .badges {
                flex: 1 1 auto;
                text-align: right;
            }

            > .buttons {
                flex: 0 0 auto;
                text-align: right;

                > .actionbutton {
                    margin: .25lh;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }*/
}

%auction-detail {
    @include auction-detail($auction-detail-max-width);
}

@if $auction-detail-export-class {

    @if str-length($auction-detail-prefix) == 0 {
        .auction-detail {
            @include auction-detail($auction-detail-max-width);
        }
    }
    @else {
        .#{$auction-detail-prefix}-auction-detail {
            @include auction-detail($auction-detail-max-width);
        }
    }
}
