﻿.avcb-scrollable-text {
    border: 1px solid getColor(base, lines);
    padding: 8px;
    max-height: 180px;
    overflow-y: scroll;
    font-size: 14px;

    > h3 {
        margin: 0 0 8px 0;
        font-size: 18px;
        font-weight: 700;
    }
}
