﻿.avcb-article-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: $gutter $gutter*.5;

    > .avcb-lot-zoom {
        flex: 1 1 375px;
        max-width: $lot-zoom-width;
        margin: 0;
    }

    > .avcb-lot-detail {
        flex: 1 1 375px;
        padding: 0 $gutter*.5*.925;
    }
}
