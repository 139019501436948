﻿$lot-zoom-export-class: true !default;
$lot-zoom-prefix: '' !default;
$lot-zoom-width: 600px !default;
$lot-zoom-background: #666 !default;
$lot-zoom-standout: #0f0 !default;

@mixin lot-zoom($width: 600px, $background: #666, $standout: #f00) {

    $loading-icon-size: 30px;
    $thumb-list-size: 72px;
    $border-size: 2px;

    position: relative;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    @if variable-exists(gutter) {
        margin-top: $gutter*-1;
        margin-right: $gutter*-1;
        margin-left: $gutter*-1;
    }

    > .thumblist {
        flex: 0 0 $thumb-list-size;
        list-style: none;
        padding: 16px;
        margin: 0;
        text-align: center;
        width: 100%;

        > .thumb {
            position: relative;
            display: inline-block;
            width: 100%;
            width: 64px;
            height: 64px;
            padding: 0;
            margin: 0 6px;
            border: 2px solid $background;
            background-color: $background;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            cursor: pointer;
            user-select: none;

            > a {
                display: block;
                position: relative;
                width: 100%;
                height: 100%;
                // https://github.com/fregante/object-fit-images
                @if mixin-exists('object-fit') {
                    @include object-fit('contain');
                    object-fit: contain;
                }
                @else {
                    object-fit: contain;
                }
            }

            > a > img {
                width: 100% !important;
                height: 100% !important;

                @if mixin-exists('object-fit') {
                    @include object-fit('contain');
                    object-fit: contain;
                }
                @else {
                    object-fit: contain;
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &.-current {
                border: 2px solid $standout;
            }
        }
    }

    > .preview {
        position: relative;
        flex: 1 1 auto;
        width: 100%;
        height: 100vw;
        margin: 0;
        border: $border-size solid $background;
        text-align: center;
        letter-spacing: 0;
        white-space: nowrap;
        font-size: 0;
        background-color: $background;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            vertical-align: middle;
            width: 0;
            height: 100%;
        }

        > img {
            width: 100%;
            height: auto;
            max-height: 100%;
        }

        > figure {
            height: 100%;
        }

        > figure > img {
            width: 100%;
            height: 100%;
            max-width: 100% !important;
            max-height: 100% !important;
            // https://github.com/fregante/object-fit-images
            @if mixin-exists('object-fit') {
                @include object-fit('contain');
                object-fit: contain;
            }
            @else {
                object-fit: contain;
            }

            &[src*="commons/img/placeholder"] {
                cursor: default;
            }

            &[role="presentation"][src*="commons/img/placeholder"] {
                display: none !important;
            }
        }

        &.-loading img {
            display: none !important;
        }

        &.-zoom-in {
            cursor: zoom-in;
        }

        > .loadingicon {
            @if mixin-exists(load-spinner) {
                @include load-spinner($loading-icon-size, $standout)
                /*@include typing-icon($loading-icon-size, $standout, 400ms);*/
            }

            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            margin: $loading-icon-size*-.5 0 0 $loading-icon-size*-.5;
        }

        &:not(.-loading) > .loadingicon {
            display: none;
        }
    }

    @media(min-width: $width) {
        flex-direction: row;
        max-width: $width;
        margin: 0 auto;

        > .thumblist {
            padding-top: 0;
        }

        > .thumblist > .thumb {
            margin: 4px 0;
        }

        > .thumblist > .thumb:first-child {
            margin-top: 0;
        }

        > .preview {
            height: $width - $thumb-list-size * 2 - $border-size * 2;
        }
    }
}

%lot-zoom {
    @include lot-zoom($lot-zoom-width, $lot-zoom-background, $lot-zoom-standout);
}

@if $lot-zoom-export-class {

    @if str-length($lot-zoom-prefix) == 0 {
        .lot-zoom {
            @include lot-zoom($lot-zoom-width, $lot-zoom-background, $lot-zoom-standout);
        }
    }
    @else {
        .#{$lot-zoom-prefix}-lot-zoom {
            @include lot-zoom($lot-zoom-width, $lot-zoom-background, $lot-zoom-standout);
        }
    }
}
