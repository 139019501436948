﻿.avcb-auction-list {
    padding: 0 .5lh;

    > .listsettings {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        > div {
            flex: 0 auto;
        }
    }

    > ul > li:last-child {
        margin-bottom: 1lh;
    }
}
