﻿$simple-carousel-export-class: true !default;
$simple-carousel-prefix: '' !default;
$simple-carousel-background: #000 !default;
$simple-carousel-slide-background: #000 !default;
$simple-carousel-ticker-time: 5000ms !default;
$simple-carousel-transition-time: 750ms !default;

@mixin simple-carousel($background: #000, $slide-background: #000, $ticker-time: 5000ms, $transition-time: 750ms) {
    position: relative;
    width: 100%;
    user-select: none;

    & > .carouselticker {
        width: 1px;
        height: 1px;
        position: absolute;
        top: 0;
        left: 0;
        background: transparent;
        transition-property: width;
    }

    &.-playing > .carouselticker {
        width: 1px;
    }

    &.-playing:not(.-transitioning) > .carouselticker {
        width: 3px;
        transition-duration: $ticker-time;
    }

    & > [data-prev-item],
    & > [data-next-item] {
        position: absolute;
        display: block;
        z-index: 10;
        top: 50%;
        width: 45px;
        height: 45px;
        margin-top: -22.5px;
        background-color: transparent;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 30%;
    }

    & > [data-prev-item] {
        left: 0;
    }

    & > [data-next-item] {
        right: 0;
    }

    & > ul {
        width: 100%;
        height: 100%;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        background-color: $background;

        & > .slideitem {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $slide-background;
            opacity: 0;
            visibility: hidden;
            transition: opacity $transition-time ease-out;
            backface-visibility: hidden;

            &.-current {
                visibility: visible;
                opacity: 1;
            }

            &.-current.-out {
                visibility: hidden;
                opacity: 0;
            }

            &.-next.-in,
            &.-prev.-in {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    [data-carousel-pager] {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 15;
        text-align: center;

        ul {
            display: inline-block;
            vertical-align: middle;
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 1em;

            li {
                display: inline-block;
                margin: .5em;
                cursor: pointer;
            }
        }
    }
}

%simple-carousel {
    @include simple-carousel(
        $simple-carousel-background, 
        $simple-carousel-slide-background, 
        $simple-carousel-ticker-time, 
        $simple-carousel-transition-time
    );
}


@if($simple-carousel-export-class) {

    @if str-length($simple-carousel-prefix) == 0 {
        .simple-carousel {
            @include simple-carousel(
                $simple-carousel-background, 
                $simple-carousel-slide-background, 
                $simple-carousel-ticker-time, 
                $simple-carousel-transition-time
            );
        }
    }
    @else {
        .#{$simple-carousel-prefix}-simple-carousel {
            @include simple-carousel(
                $simple-carousel-background, 
                $simple-carousel-slide-background, 
                $simple-carousel-ticker-time, 
                $simple-carousel-transition-time
            );
        }
    }
}

%transition-left {

    & > ul {

        & > li {
            transition-property: left;
            left: 100%;
            transition-timing-function: ease;
            visibility: hidden;

            &.-out,
            &.-current.-out {
                opacity: 1;
                transition-property: left;
                left: -100%;
                visibility: visible;
                z-index: 2;
            }

            &.-in + .out,
            &.-in + .-current.-out {
                left: 100%;
            }

            &.-next,
            &.-prev:last-child {
                left: 100%;
                z-index: 1;
                transition-property: none;
            }

            &.-prev {
                left: -100%;
                z-index: 1;
                transition-property: none;
            }

            &.-next.-in,
            &.-prev.-in {
                visibility: visible;
                transition-property: left;
                left: 0;
            }

            &.-current {
                visibility: visible;
                left: 0;
                z-index: 10;
            }
        }
    }
}
