$mobile-submenu-export-class: true !default;
$mobile-submenu-prefix: '' !default;
$mobile-submenu-icon-color: #999 !default;

@mixin mobile-submenu($icon-color: #999) {
  margin-bottom: 0;
  box-shadow: 0 0 1px rgba(0,0,0,.25);

  > .title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-size: 1rem;
      margin: 0;
      padding: 12px;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
  }

  > .title::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;

      @if mixin-exists('svg-icon') {
        @include svg-icon('menu-icon', $icon-color, 24px, center);
      }
  }

  > .content {
      display: none;
      margin: 0;
      padding: 0 12px 12px 12px;
  }


  &.-open {

      > .title::after {

        @if mixin-exists('svg-icon') {
          @include svg-icon('cross-icon', $icon-color);
        }
      }

      > .content {
          display: block;
      }
  }
}

%mobile-submenu {
  @include mobile-submenu($mobile-submenu-icon-color);
}

@if $mobile-submenu-export-class {

  @if str-length($mobile-submenu-prefix) == 0 {
    .mobile-submenu {
      @include mobile-submenu($mobile-submenu-icon-color);
    }
  }
  @else {
    .#{$mobile-submenu-prefix}-mobile-submenu {
      @include mobile-submenu($mobile-submenu-icon-color);
    }
  }
}