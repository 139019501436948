﻿$document-detail-export-class: true !default;
$document-detail-prefix: '' !default;
$document-detail-background: #fff !default;
$document-detail-approved: #090 !default;
$document-detail-rejected: #900 !default;
$document-detail-pending: #990 !default;
$document-detail-border: #eee !default;

@mixin document-detail($background: #fff, $approved: #090, $rejected: #900, $pending: #990, $border: #eee) {
    width: 100%;
    max-width: 960px;
    margin: 0;
    border: 1px solid $border;

    > .documentimages {
        position: relative;
        width: 100%;
        height: 56.25vw;
        max-width: 960px;
        max-height: 540px;
        margin: 0 auto;
        background-color: lighten($border, 10%);
        user-select: none;

        > ul {
            background-color: lighten($border, 10%);

            > .slideitem {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
                background-color: lighten($border, 10%);
                text-align: center;

                > img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        > .carouselpager {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 5rem;
            height: 1lh;
            background-color: $background;

            > ul {
                list-style: none;
                position: absolute;
                width: 100%;
                margin: 0;
                padding: 0;
                font-weight: 700;

                > .pageritem {
                    display: inline-block;
                    width: 50%;
                    text-align: center;
                    background-color: lighten($border, 10%);
                    color: $border;
                    transition: color $transition-duration, background-color $transition-duration;
                    cursor: pointer;

                    &.-current {
                        background-color: $border;
                        color: getColor(base, primary);
                    }
                }
            }
        }
    }

    > .documentdata {
        padding: .5lh;

        > .title {
            margin: 0 0 .5lh 0;
            white-space: nowrap;
            user-select: none;

            > span {
                vertical-align: middle;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 1em;
                height: 1em;
                margin-right: .5em;
                border-radius: 50%;
                vertical-align: middle;
            }

            &.-pending {

                &::before {
                    /*background-color: getColor(state, warning);
                    @include svg-icon('typing-icon', getColor(text, inverted));
                    background-size: 60%;*/
                    display: none;
                }
            }

            &.-accepted {

                &::before {
                    background-color: getColor(state, success);
                    @include svg-icon('check-icon', getColor(text, inverted));
                    background-size: 60%;
                }
            }

            &.-refused,
            &.-expired {

                &::before {
                    background-color: getColor(state, error);
                    @include svg-icon('warning-icon', getColor(text, inverted));
                    background-size: 60%;
                }
            }
        }

        > ul {
            list-style: none;
            padding: 0;
        }
    }
}

%document-detail {
    @include document-detail(
        $document-detail-background,
        $document-detail-approved,
        $document-detail-rejected,
        $document-detail-pending,
        $document-detail-border
    )
}

@if $document-detail-export-class {

    @if str-length($document-detail-prefix) == 0 {

        .document-detail {
            @include document-detail(
                $document-detail-background,
                $document-detail-approved,
                $document-detail-rejected,
                $document-detail-pending,
                $document-detail-border
            )
        }
    }
    @else 
    {
        .#{$document-detail-prefix}-document-detail {
            @include document-detail(
                $document-detail-background,
                $document-detail-approved,
                $document-detail-rejected,
                $document-detail-pending,
                $document-detail-border
            )
        }

    }
}
