﻿$radio-field-export-class: true !default;
$radio-field-prefix: '' !default;
$radio-field-default-color: #333 !default;
$radio-field-outline-color: #00f !default;
$radio-field-error-color: #900 !default;
$radio-field-disabled-color: #999 !default;

@mixin radio-field($default-color: #333, $outline-color: #00f, $error-color: #900, $disabled-color: #999) {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    > label {
        position: relative;
        font-size: 1em;
        cursor: default;
        padding-right: 1.5em;
        flex: 0 0 auto;
        margin-bottom: .25em;

        > .labelbadge {

            @if mixin-exists('labelbadge') {
                position: absolute;
                right: 0;
                top: 50%;
                margin-top: -11px;
                @include labelbadge();
            }
        }

        > .labelrequired {
            display: none;
            color: $error-color;
        }
    }

    > input[type="radio"] {
        position: absolute;
        left: -1000000px;

        + label {
            position: relative;
            display: inline-block;
            padding-left: 24px;
            padding-right: 10px;
            font-size: 1em;
            cursor: pointer;
            margin: 0;
            flex: 0 auto;

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 20px;
                height: 20px;
                border: 2px solid $default-color;
                border-radius: 50%;
                margin-top: -10px;
                line-height: 1;
            }
        }

        &:not([disabled]):focus + label::before,
        &:not([disabled]):active + label::before {
            border: 2px solid $outline-color;
        }

        &[disabled],
        &[readonly] {

            + label {
                color: darken($disabled-color, 25%);
                cursor: not-allowed;

                &::before {
                    border: 2px solid $disabled-color;
                }
            }

            &:checked {

                + label {

                    &::after {
                        background-color: $disabled-color;
                    }
                }
            }
        }

        &:checked + label::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 5px;
            width: 10px;
            height: 10px;
            margin-top: -5px;
            border-radius: 50%;
            background-color: $default-color;
        }
    }

    > .validationmessage {

        @if mixin-exists('validationmessage') {
            @include validationmessage();
        }
    }

    &.-required > label > .labelrequired {
        display: inline;
    }

    &.-inline {
        flex-direction: row;
        flex-wrap: wrap;

        & > label {
            flex: 0 auto;

            > .labelbadge {
                display: none !important;
            }
        }

        > input[type="radio"] + label {
            flex: 0 auto;
            margin-right: .5em;
        }
    }

    &.-valid > label > .labelbadge {

        @if mixin-exists('labelbadge-valid') {
            display: block;
            @include labelbadge-valid();
        }
    }

    &.-warning > label > .labelbadge {

        @if mixin-exists('labelbadge-warning') {
            display: block;
            @include labelbadge-warning();
        }
    }

    &.-error {

        > label > .labelbadge {

            @if mixin-exists('labelbadge-error') {
                display: block;
                @include labelbadge-error();
            }
        }

        > input[type="radio"]:not([disabled]):not([readonly]) {

            + label {

                &::before {
                    border-color: $error-color;
                }

                &::after {
                    background-color: $error-color;
                }
            }
        }

        > .validationmessage {
            display: block;
        }
    }
}

%radio-field {
    @include radio-field(
        $radio-field-default-color,
        $radio-field-outline-color,
        $radio-field-error-color,
        $radio-field-disabled-color
    );
}

@if $radio-field-export-class {

    @if str-length($radio-field-prefix) == 0 {

        .radio-field {
            @include radio-field(
                $radio-field-default-color,
                $radio-field-outline-color,
                $radio-field-error-color,
                $radio-field-disabled-color
            );
        }
    }
    @else {

        .#{$radio-field-prefix}-radio-field {
            @include radio-field(
                $radio-field-default-color,
                $radio-field-outline-color,
                $radio-field-error-color,
                $radio-field-disabled-color
            );
        }
    }
}
