﻿.arsv-input-field.-address {
    flex: 1 1 100% !important;
    max-width: none !important;

    > label {
        padding-right: .5em;

        > .labeltext.-right,
        > .labelrequired.-right {
            float: right;
        }

        > .labeltext.-right {
            width: 5.5em;
        }

        >.labelrequired.-right {
            width: .5em;
        }
    }
    // Indirizzo
    > .streetname {
        width: auto;
        flex: 1 1 auto;
    }
    // Numero civico
    > .streetnumber {
        max-width: 6em;
        flex: 0 1 6em;
    }
}
