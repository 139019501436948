﻿.arsv-house-badge {
    font-size: typeScale(2);
    color: getColor(text, primary);

    > img,
    > span{
        vertical-align: middle;
    }

    &[href]:hover,
    &[href]:active {

        > span {
            text-decoration: underline;
        }
    }
}