﻿$auction-list-export-class: true !default;
$auction-list-prefix: '' !default;
$auction-list-box-item-size: 320px !default;
$auction-list-settings-bgcolor: #ddd !default;
$auction-list-message-color: #999 !default

@mixin auction-list($box-item-size: 320px, $settings-bgcolor: #ddd, $message-color: #999) {
    max-width: $box-item-size*4;

    > .title {
        @if variable-exists('font-serif') {
            font-family: $font-serif;
        }
    }

    > .listsettings {
        user-select: none;
        background-color: $settings-bgcolor;

        @if variable-exists('gutter') {
            padding: $gutter*.5;
            margin-bottom: $gutter*.5;
        }

        + .listsettings {
            border-top: 1px solid darken($settings-bgcolor, 5%);

            @if variable-exists('gutter') {
                margin-top: $gutter*-.5;
            }
        }
    }

    > ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 0 $gutter 0;

        @if variable-exists('gutter') {
            margin: 0 $gutter*-.25;
        }
        @else {
            margin: 0;
        }


        > li {
            flex: 0 100%;

            @if variable-exists('gutter') {
                padding: $gutter*.25;
            }
        }
    }

    > .message {
        padding: 1lh;
        text-align: center;
        font-weight: 300;
        text-transform: uppercase;
        user-select: none;
        color: $message-color;

        > * {
            display: inline-block;
            margin-bottom: 1em;
        }

        > a {
            border: 0;
            box-shadow: none;
        }

        @if function-exists(typeScale) {
            font-size: typeScale(3);
        }
    }

    &.-inline {

        > ul {
            padding: 0;
            margin: 0;

            > li {
                position: relative;
                width: 100%;
                border-bottom: 1px solid $message-color;

                @if variable-exists('gutter') {
                    padding: $gutter*.5 0;
                }

                &:first-child {
                    border-top: 1px solid $message-color;
                }
            }
        }
    }

    &.-box {

        @media(max-width: $box-item-size * 2 - 1) {

            > .list {

                > .item {
                    max-width: 100%;
                }

            }
        }

        @media (min-width: $box-item-size*2) {

            > .list {
                justify-content: flex-start;

                > .item {
                    flex: 0 50%;
                    max-width: 50%
                }
            }
        }

        @media (min-width: $box-item-size*3) {

            > .list {

                > .item {
                    flex: 0 33.332%;
                    max-width: 33.332%;
                }
            }
        }
    }
}

%auction-list {
    @include auction-list($auction-list-box-item-size, $auction-list-settings-bgcolor, $auction-list-message-color);
}

@if $auction-list-export-class {

    @if str-length($auction-list-prefix) == 0 {
        .auction-list {
            @include auction-list($auction-list-box-item-size, $auction-list-settings-bgcolor, $auction-list-message-color);
        }
    }
    @else {
        .#{$auction-list-prefix}-auction-list {
            @include auction-list($auction-list-box-item-size, $auction-list-settings-bgcolor, $auction-list-message-color);
        }
    }
}

.arsv-auction-list {
    $box-item-size: $auction-list-box-item-size;

    > .title {
        margin: 0 0 1em 0;
        text-align: center;
    }

    &.-inline {
        max-width: 1100px;

        @media(--medium) {

            & > .list {

                & > .item {
                    padding: $gutter 0;
                }
            }
        }
    }
}
