﻿$accordion-menu-export-class: true !default;
$accordion-menu-prefix: '' !default;
$accordion-menu-max-width: 400px !default;
$accordion-menu-color: #666 !default;
$accordion-menu-text-color: #000 !default;
$accordion-menu-border-color: #999 !default;
$accordion-menu-icon: '' !default;

@mixin accordion-menu($max-width: 400px, $color: #666, $text-color: #000, $border-color: #999, $icon: '') {
    width: 100%;
    max-width: $max-width;
    padding: .5lh 1lh;

    > .title {
        font-size: 1rem;
        font-weight: 700;
        user-select: none;
    }

    > .content {
        list-style: none;
        padding: 0;

        > .item {
            border-top: 1px dashed $border-color;
            font-weight: 300;
            line-height: 1;

            &[aria-expanded="false"] {
                height: 48px;
                overflow: hidden;
            }

            &[aria-expanded="true"],
            &:focus {

                > .title {

                    @if mixin-exists(svg-icon) and str-length($icon) > 0 {
                        &::after {
                            transform: rotate(180deg);
                        }
                    }
                    @else {
                        &::after {
                            content: '–';
                        }
                    }
                }
            }

            &:last-child {
                border-bottom: 1px dashed $border-color;
            }

            > .title {
                position: relative;
                margin: 0;
                padding: .5lh 0;
                font-size: 1rem;
                font-weight: 300;
                color: $text-color;
                cursor: pointer;
                outline: none;
                user-select: none;

                @if mixin-exists(svg-icon) and str-length($icon) > 0 {

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: .5em;
                        width: 1em;
                        height: 1em;
                        margin-top: -.5em;
                        @include svg-icon($icon, $color);
                        transform: rotate(0deg);
                    }
                }
                @else {

                    &::after {
                        content: '+';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 1.5em;
                        height: 1.5em;
                        margin-top: -.75em;
                        font-size: 1.5em;
                        font-weight: 700;
                        text-align: center;
                        color: $color;
                    }
                }
            }

            > .content {
                padding: 0 .5lh 1lh .5lh;
            }
        }
    }

    &.-no-padding {
        padding: 0;
    }
}

%accordion-menu {
    @include accordion-menu(
        $accordion-menu-max-width,
        $accordion-menu-color,
        $accordion-menu-text-color,
        $accordion-menu-border-color,
        $accordion-menu-icon
    );
}

@if $accordion-menu-export-class {

    @if str-length($accordion-menu-prefix) == 0 {
        .accordion-menu {
            @include accordion-menu(
                $accordion-menu-max-width,
                $accordion-menu-color,
                $accordion-menu-text-color,
                $accordion-menu-border-color,
                $accordion-menu-icon
            );
        }
    }
    @else {
        .#{$accordion-menu-prefix}-accordion-menu {
            @include accordion-menu(
                $accordion-menu-max-width,
                $accordion-menu-color,
                $accordion-menu-text-color,
                $accordion-menu-border-color,
                $accordion-menu-icon
            );
        }
    }
}
