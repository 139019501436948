﻿$modal-window-prefix: '' !default;
$modal-window-export-class: true !default;
$modal-window-background-color: rgba(0,0,0,.75) !default;
$modal-window-content-background: #fff !default;
$modal-window-transition-duration: 400ms !default;

@mixin modal-window($background: rgba(0,0,0,.75), $content-background: #fff, $transition: 400ms) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 150;
    background-color: $background;
    visibility: hidden;
    opacity: 0;
    transition: opacity $transition*.5;

    > .modalcontent {
        position: relative;
        width: 90vw;
        height: 90vh;
        margin: 5vh 5vw;
        padding: 1lh;
        background-color: $content-background;
        transform: translateY(-30vh);
        transition: transform $transition*.5;

        > .message {
            font-size: 1rem;
            margin-bottom: 1em;
        }

        > .actions {

           > button {
               margin: 0 .5em;
           }
        }

        > [data-content] {
            position: relative;
            width: 100%;
            max-height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .modalclose {
            position: absolute;
            top: .25lh;
            right: .5lh;
            font-size: 150%;
            line-height: 1;
            font-weight: 700;
            text-transform: uppercase;
            cursor: pointer;
        }
    }


    &.-open {
        visibility: visible;
        opacity: 1;

        > .modalcontent {
            transform: translateY(0);
        }
    }

    &.-small {

        @media(--medium) {

            > .modalcontent {
                width: 50vw;
                height: 50vh;
                margin: 25vh 25vw;
            }
        }
    }

    &.-center {

        > .modalcontent {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
        }
    }
}

%modal-window {
    @include modal-window($modal-window-background-color, $modal-window-content-background, $modal-window-transition-duration);
}

@if($modal-window-export-class) {

    @if str-length($modal-window-prefix) == 0 {
        .modal-window {
            @include modal-window($modal-window-background-color, $modal-window-content-background, $modal-window-transition-duration);
        }
    }
    @else {
        .#{$modal-window-prefix}-modal-window {
            @include modal-window($modal-window-background-color, $modal-window-content-background, $modal-window-transition-duration);
        }
    }
}
