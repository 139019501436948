﻿$lot-detail-export-class: true !default;
$lot-detail-prefix: '' !default;

@mixin lot-detail() {
    position: relative;

    > .lotnavigation {
        position: relative;
        width: 100%;
    }

    > .lotdata {
        width: 100%;
    }

    > .title {
        margin: 0;
        white-space: normal;
        font-weight: 700;

        @if function-exists(typeScale) {
            font-size: typeScale(4);
        }
    }

    > .auction {

        > .auctiondate {
            color: getColor(text, primary);

            > .dayofweek {
                text-transform: capitalize;
            }
        }
    }

    > .auctionhouse {
        display: block;
        text-transform: uppercase;
        user-select: none;

        @if function-exists(typeScale) {
            font-size: typeScale(1);
        }

        > .arsv-country-flag {
            vertical-align: middle;
        }

        > a {
            vertical-align: middle;
        }
    }

    > .lotstatus {

        @if variable-exists(gutter) {
            margin: $gutter 0;
        }
    }

    > .lotterms {
        letter-spacing: 2px;
        max-width: none;

        @if function-exists(typeScale) {
            font-size: typeScale(1);
        }

        > .content > .item > .title {
            @if function-exists(typeScale) {
                font-size: typeScale(2);
            }
        }

        > .content > .item > .content {
            line-height: 1.5;
        }
    }

    > p > br:only-child {
        display: none;
    }
}

%lot-detail {
    @include lot-detail();
}

@if $lot-detail-export-class {

    @if str-length($lot-detail-prefix) == 0 {
        .lot-detail {
            @include lot-detail();
        }
    }
    @else {
        .#{$lot-detail-prefix}-lot-detail {
            @include lot-detail();
        }
    }
}
