﻿.avcb-documents-wrapper {
    padding: $gutter*.5 $gutter*.5 $gutter $gutter*.5;

    > .title {
        white-space: nowrap;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 .5em 0;
    }

    > .title > a {
        display: inline-block;
        font-size: typeScale(1);
    }

    > .upload {
        text-align: center;
        padding: $gutter 0;

        > .newdocument {
            font-size: 1rem;
        }
    }

    > .documentstable {

        > table > .tableheading > tr > th:first-child {
            padding-left: 0;
        }

        > table > .tablecontent > .tablerow {

            > .documenttype {
                font-weight: bold;
                text-decoration: underline;
                user-select: none;
            }

            > .documentstatus { 
                user-select: none;

                > span {
                    vertical-align: middle;
                    white-space: nowrap;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: typeScale(1);

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1.5em;
                        height: 1.5em;
                        margin-right: .5em;
                        border-radius: 50%;
                    }
                }

                &.-pending {

                    > span::before {
                        background-color: getColor(state, warning);
                        @include svg-icon('typing-icon', getColor(text, inverted));
                        background-size: 60%;
                    }
                }

                &.-accepted {

                    > span::before {
                        background-color: getColor(state, success);
                        @include svg-icon('check-icon', getColor(text, inverted));
                        background-size: 60%;
                    }
                }

                &.-refused,
                &.-expired {

                    > span::before {
                        background-color: getColor(state, error);
                        @include svg-icon('warning-icon', getColor(text, inverted));
                        background-size: 60%;
                    }
                }
            }

            > .documentaction {
                user-select: none;

                > form > .modal {
                    display: block;
                }
            }
        }

        > .tableactionconfirm > .modalcontent {
            height: auto;
            text-align: center;

            > .message {
                margin-bottom: 1rem;
            }
        }

        > .tableempty {
            font-size: 1rem;
            padding: $gutter;
            text-align: center;
            text-transform: uppercase;
            color: getColor(base, lines);
            user-select: none;
        }
    }
}
